<template>
	<div class="sidebar">
		<div v-if="isSzrqTenant" class="box-logo">
			<img class="logo-szrq" :class="{ small: fold }" src="@/assets/imgs/icon/logo-szrq.png" />
		</div>
		<div v-else class="box-logo" @click="handleJump">
			<svg class="logo" aria-hidden="true">
				<use xlink:href="#icon-Logo"></use>
			</svg>
			<span class="title" v-show="!fold">IoT监控平台</span>
		</div>
		<div class="box-menu">
			<el-menu :default-active="activeMenu" :collapse="fold" :collapse-transition="false">
				<submenu
					v-for="(route, index) in routeList"
					:key="index"
					:item="route"
					:base-path="route.path"
					:active-menu="activeMenu"
				/>
			</el-menu>
		</div>
		<div class="box-robot" v-if="hasBigScreenAuth">
			<div class="entry-tip bounce" v-show="!fold">点我看监控大屏噢</div>
			<img
				v-show="!fold"
				class="icon-robot"
				src="@/assets/imgs/pic/robot.png"
				@click="$router.push('/big-screen')"
			/>
			<img
				v-show="fold"
				class="icon-robot-hide"
				src="@/assets/imgs/pic/robot-hide.png"
				@click="$router.push('/big-screen')"
			/>
		</div>
	</div>
</template>

<script>
import Submenu from './Submenu'
import { mapGetters } from 'vuex'
import { isSzrqTenant } from '@/utils'

export default {
	name: 'LayoutSidebar',
	components: { Submenu },
	computed: {
		...mapGetters({
			fold: 'app/fold',
			routes: 'app/routes',
		}),
		isSzrqTenant: () => isSzrqTenant,
		// 可展示路由列表
		routeList() {
			return this.routes.filter(route => route.meta && !route.hidden && !route.meta?.hiddenButCanEnter)
		},
		activeMenu() {
			const { meta, path } = this.$route
			if (meta.activeMenu) {
				return meta.activeMenu
			}
			return path
		},
		hasBigScreenAuth() {
			return this.$has(['monitor:data-screen'])
		},
	},
	methods: {
		handleJump() {
			if (this.$has('monitor:board')) {
				this.$router.push('/')
			}
		},
	},
}
</script>
