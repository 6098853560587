<template>
	<div class="charts-box" ref="charts-box">
		<div class="channel-information-search">
			<div class="dimension">
				<span class="dimension-text">维度选择：</span>
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
					全选
				</el-checkbox>
				<el-checkbox-group v-model="checkedDimension" @change="handleCheckedDimensionChange">
					<el-checkbox v-for="item in dimensionOptions" :label="item.key" :key="item.key">
						{{ item.name }}
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<div class="other-condition">
				<gc-custom-search
					class="search"
					width="450px"
					key-word="时间区间"
					type="date"
					:search.sync="innerparams['time']"
					date-picker-type="datetimerange"
				>
					<template v-slot:icon>
						<i class="iconfont icon-riqi"></i>
					</template>
				</gc-custom-search>
				<div class="ops-btn">
					<el-button type="primary" v-click-blur @click="analysis">分析</el-button>
					<el-button v-click-blur @click="reset">重置</el-button>
				</div>
			</div>
		</div>
		<div class="channel-information-echarts-box">
			<div
				v-if="echartsFlag && checkedDimension.length > 0"
				:id="'channel-information'"
				:style="{
					height: chartsHeight + 'px',
					width: '100%',
				}"
			></div>
			<gc-empty v-else></gc-empty>
		</div>
	</div>
</template>

<script>
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/visualMap')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/markLine')
require('echarts/lib/component/markPoint')
require('echarts/lib/component/legend')
require('echarts/lib/component/toolbox')
require('echarts/lib/component/title')
let elementResizeDetectorMaker = require('element-resize-detector')
import {
	channelInfomationChartsChannel,
	channelInfomationChartsGas,
	dimensionOptionsGas,
	dimensionOptionsChannel,
	chartsConfigChannel,
	chartsConfigGas,
} from './function.js'

export default {
	name: 'GcChartsBox',
	props: {
		currentDevices: {
			type: Array,
			default: () => {
				return []
			},
		},
		currentDeviceObj: {
			type: Object,
			default: () => {
				return {}
			},
		},
		params: {
			type: [Array, Object],
			default: () => {
				return []
			},
		},
		tabData: {
			type: Object,
			default: () => {
				return {}
			},
		},
		chartType: {
			type: String,
			default: 'channel',
		},
		echartsFlag: {
			type: Boolean,
			default: () => {
				return true
			},
		},
	},
	data() {
		return {
			chart: null,
			color: ['#4D6BFF', '#3BBE40', '#FFCD1B', '#FF85D5', '#FF7700'],
			lengthMap: {
				1: 6,
				2: 4,
				3: 3,
				4: 3,
				5: 2,
				6: 2,
			},
			checkAll: true,
			checkedDimension: [],
			isIndeterminate: false,
			innerparams: {
				time: null,
			},
			option: null,
			chartsHeight: '', //图表高度
		}
	},
	watch: {
		currentDevices: {
			deep: true,
			immediate: true,
			handler: function (val) {
				if (val && val.length) {
					if (this.echartsFlag && this.checkedDimension.length > 0) {
						this.channelInfomationCharts()
					}
				}
			},
		},
		currentDeviceObj: {
			deep: true,
			handler: function (val) {
				if (val) {
					if (this.echartsFlag && this.checkedDimension.length > 0) {
						this.channelInfomationCharts()
					}
				}
			},
		},
		'params.time': {
			deep: true,
			immediate: true,
			handler: function (val) {
				if (val) {
					this.innerparams.time = val
				}
			},
		},
		innerparams: {
			deep: true,
			immediate: true,
			handler: function (val) {
				this.$emit('update:params', val)
			},
		},
	},
	computed: {
		dimensionOptions() {
			return this.chartType === 'gas' ? dimensionOptionsGas : dimensionOptionsChannel
		},
	},
	created() {},
	mounted() {
		this.checkedDimension = this.dimensionOptions.map(item => {
			return item.key
		})
	},
	beforeDestory() {
		if (this.chart != null && this.chart != '' && this.chart != undefined) {
			this.chart.dispose() //销毁
		}
	},
	methods: {
		handleCheckAllChange(val) {
			this.checkedDimension = val
				? this.dimensionOptions.map(item => {
						return item.key
				  })
				: []
			this.isIndeterminate = false
			this.$emit('analysis', this.checkedDimension)
		},
		handleCheckedDimensionChange(value) {
			let checkedCount = value.length
			this.checkAll = checkedCount === this.dimensionOptions.length
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.dimensionOptions.length
			this.$emit('analysis', this.checkedDimension)
		},
		// 流量计图表渲染
		channelInfomationCharts() {
			let currentCharts = []
			let chartsConfig = this.chartType === 'gas' ? chartsConfigGas : chartsConfigChannel
			Object.keys(chartsConfig).forEach(key => {
				let findItem = currentCharts.find(el => el == key)
				let findChecked = this.checkedDimension.find(m => key.toUpperCase().indexOf(m.toUpperCase()) != -1)
				// 当前没有渲染且所有指标包含选中的指标
				if (findChecked && !findItem) {
					currentCharts.push(key)
				}
			})
			this.chartsHeight = currentCharts.length * (this.chartType === 'gas' ? 440 : 360)
			this.$nextTick(() => {
				let myChart = echarts.getInstanceByDom(document.getElementById('channel-information'))
				if (!myChart) {
					this.chart = echarts.init(document.getElementById('channel-information'))
				}
				if (this.chartType === 'gas') {
					let option = channelInfomationChartsGas(this, chartsConfig, currentCharts)
					this.option = option
				} else {
					let option = channelInfomationChartsChannel(this, chartsConfig, currentCharts)
					this.option = option
				}
				this.chart.clear()
				this.chart.setOption(this.option)
				//监听元素变化
				let erd = elementResizeDetectorMaker()
				erd.listenTo(document.getElementById('channel-information'), () => {
					this.$nextTick(function () {
						//使echarts尺寸重置
						this.chart.resize()
					})
				})
			})
		},
		//分析
		analysis() {
			this.$emit('analysis', this.checkedDimension)
		},
		// 重置
		reset() {
			this.innerparams.time = null
			this.$emit('analysis', this.checkedDimension)
		},
	},
}
</script>
<style lang="scss" scoped>
.charts-box {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.channel-information-search {
		.dimension {
			display: flex;
			padding-bottom: 20px;
			&-text {
				line-height: 21px;
				color: #666666;
			}
			::v-deep .el-checkbox {
				margin-right: 20px;
				.el-checkbox__label {
					padding-left: 6px;
				}
			}
		}
		.other-condition {
			display: flex;
			padding-bottom: 40px;
			.ops-btn {
				@include base-button(80px);
				padding-left: 20px;
			}
		}
	}
	.channel-information-echarts-box {
		flex: 1;
		height: 0;
		overflow: auto;
	}
}
</style>
<style lang="scss">
.chartTooltip {
	min-width: 280px;
	.title {
		margin-top: 5px;
	}
	.content {
		padding-top: 3px;
		display: flex;
		justify-content: space-between;
	}
}
</style>
