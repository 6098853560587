import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
// import { initSentry } from "@/sentry";
import './global'

Vue.config.productionTip = false
// initSentry(Vue);
new Vue({
	el: '#app',
	store,
	router,
	render: h => h(App),
})
