import Layout from '@/layout'

export default [
	{
		order: 3,
		path: '/customer-manage',
		component: Layout,
		meta: {
			title: '客户管理',
			subtitle: '客户',
			hiddenCrumb: true,
			icon: 'icon-lianxiren',
		},
		redirect: '/systemForTenant/open-account-setting',
		children: [
			{
				path: '',
				name: 'customerManage',
				meta: {
					title: '客户管理',
					keepAlive: true,
					permissions: ['monitor:ledger:user:list'],
				},
				component: () => import('@/pages/customer-manage/index.vue'),
			},
			{
				path: 'use-gas-analysis',
				name: 'customerManage',
				meta: {
					title: '用气分析',
					keepAlive: false,
					permissions: ['monitor:ledger:user:list'],
					hidden: true,
					customCrumb: [
						{ customCrumbTitle: '客户管理', path: '/customer-manage' },
						{ customCrumbTitle: '用气分析' },
					],
				},
				component: () => import('@/pages/customer-manage/UseGasAnalysis.vue'),
			},
		],
	},
]
