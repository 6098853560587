<template>
	<div>
		<el-image
			v-for="(url, index) in urls"
			:key="index"
			:src="url"
			:preview-src-list="urls"
			:initial-index="initialIndex"
			fit="cover"
			lazy
			class="photo"
			@click="handleOpenPhoto(index)"
		></el-image>
	</div>
</template>

<script>
export default {
	name: 'GcImage',
	props: {
		urls: {
			type: Array,
			default: () => {
				return Array
			},
		},
	},
	data() {
		return {
			initialIndex: 0,
		}
	},
	methods: {
		handleOpenPhoto(index) {
			this.initialIndex = index
		},
	},
}
</script>
<style lang="scss" scoped>
.photo {
	width: 100px;
	height: 100px;
	margin-right: 16px;
	margin-bottom: 16px;
}
</style>
