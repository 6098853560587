import { getLS, setLS } from '@/utils/storage'

const LSKey = 'tagsView'

export default {
	namespaced: true,
	state: {
		tags: getLS(LSKey) || [],
	},
	mutations: {
		// 添加路由tag
		ADD_VIEW: (state, view) => {
			const exist = state.tags.some(v => {
				return v.fullPath === view.fullPath
			})
			if (exist) return

			state.tags.push(
				Object.assign({}, view, {
					title: (view.query && view.query.title) || view.meta.title || 'no-name',
					matched: [],
				}),
			)
			setLS(LSKey, state.tags)
		},
		// 删除单个路由
		DEL_VIEW: (state, view) => {
			for (const [i, v] of state.tags.entries()) {
				if (v.fullPath === view.fullPath) {
					state.tags.splice(i, 1)
					break
				}
			}
			setLS(LSKey, state.tags)
		},
		// 删除所有路由，固定的页面（affix）排除在外
		DEL_ALL_VIEWS: state => {
			const affixTags = state.tags.filter(tag => tag.meta.affix)
			state.tags = affixTags
			setLS(LSKey, affixTags)
		},
		// 路由参数不同时更新，参数不同时多开，暂不需要该功能
		UPDATE_VIEW: (state, view) => {
			for (let v of state.tags) {
				if (v.fullPath === view.fullPath) {
					v = Object.assign(v, view)
					break
				}
			}
		},
		CLOSE_TO_RIGHT: (state, view) => {
			const index = state.tags.findIndex(item => item.fullPath === view.fullPath)
			if (index > -1) {
				state.tags.length = index + 1
			}
			setLS(LSKey, state.tags)
		},
		CLOSE_TO_LEFT: (state, view) => {
			const index = state.tags.findIndex(item => item.fullPath === view.fullPath)
			if (index > -1) {
				state.tags.splice(0, index)
			}
			setLS(LSKey, state.tags)
		},
		CLOSE_OTHERS: (state, view) => {
			state.tags = [view]
			setLS(LSKey, state.tags)
		},
	},
	actions: {
		addView({ commit }, view) {
			commit('ADD_VIEW', view)
		},
		delView({ commit, state }, view) {
			return new Promise(resolve => {
				commit('DEL_VIEW', view)
				resolve([...state.tags])
			})
		},
		delAllViews({ commit, state }) {
			return new Promise(resolve => {
				commit('DEL_ALL_VIEWS')
				resolve([...state.tags])
			})
		},
		updateVisitedView({ commit }, view) {
			commit('UPDATE_VIEW', view)
		},
		closeToRight({ commit }, view) {
			return new Promise(resolve => {
				commit('CLOSE_TO_RIGHT', view)
				resolve()
			})
		},
		closeToLeft({ commit }, view) {
			return new Promise(resolve => {
				commit('CLOSE_TO_LEFT', view)
				resolve()
			})
		},
		closeOthers({ commit }, view) {
			return new Promise(resolve => {
				commit('CLOSE_OTHERS', view)
				resolve()
			})
		},
	},
}
