<template>
	<gc-dialog title="修改密码" :show.sync="visible" @ok="handleOk" @cancel="visible = false" @close="visible = false">
		<div class="warning-tip" v-if="showWarningTip">
			<span class="iconfont icon-lingdang" style="font-size: 13px; padding-right: 8px"></span>
			密码强度较低，请先提升密码强度后再继续后续操作
		</div>
		<el-form ref="form" :model="form" :rules="formRules" label-position="top">
			<!-- 阻止浏览器自动填充密码 -->
			<input type="text" name="username" value="" style="position: absolute; left: -10000px" />
			<input type="password" name="password" value="" style="position: absolute; left: -10000px" />
			<el-form-item label="原始密码：" prop="oldPwd">
				<el-input
					v-model="form.oldPwd"
					placeholder="请填写原始密码"
					:type="showOldPwd ? 'text' : 'password'"
					@input="handlePwdChange('oldPwd')"
					@change="handlePwdChange('oldPwd')"
					@blur="handlePwdChange('oldPwd')"
				>
					<i
						slot="suffix"
						class="iconfont"
						:class="showOldPwd ? 'icon-zhengyan' : 'icon-biyan'"
						@click="showOldPwd = !showOldPwd"
					/>
				</el-input>
			</el-form-item>
			<el-form-item label="新密码：" prop="newPwd">
				<el-input
					v-model="form.newPwd"
					placeholder="请填写新密码"
					:type="showNewPwd ? 'text' : 'password'"
					@input="handlePwdChange('newPwd')"
					@change="handlePwdChange('newPwd')"
					@blur="handlePwdChange('newPwd')"
				>
					<i
						slot="suffix"
						class="iconfont"
						:class="showNewPwd ? 'icon-zhengyan' : 'icon-biyan'"
						@click="showNewPwd = !showNewPwd"
					/>
				</el-input>
			</el-form-item>
			<el-form-item label="确认新密码：" prop="confirmPwd">
				<el-input
					v-model="form.confirmPwd"
					placeholder="请再次填写新密码"
					:type="showConfirmPwd ? 'text' : 'password'"
					@keyup.enter.native="handleOk"
					@input="handlePwdChange('confirmPwd')"
					@change="handlePwdChange('confirmPwd')"
					@blur="handlePwdChange('confirmPwd')"
				>
					<i
						slot="suffix"
						class="iconfont"
						:class="showConfirmPwd ? 'icon-zhengyan' : 'icon-biyan'"
						@click="showConfirmPwd = !showConfirmPwd"
					/>
				</el-input>
			</el-form-item>
		</el-form>
	</gc-dialog>
</template>

<script>
import { ruleRequired, ruleMinLength, ruleMaxLength, ruleComplexPassValidate } from '@/utils/rules'
import { apiModifyTenantPwd, apiModifyAdminPwd } from '@/apis/login.api'
import { mapGetters } from 'vuex'
import { encrypt } from '@/utils'

export default {
	name: 'FactoryDialog',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		showWarningTip: {
			type: Boolean,
			default: false,
		},
		loginInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		const equalPassValidate = (rule, value, callback) => {
			if (value !== this.form.newPwd) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			form: {
				oldPwd: '',
				newPwd: '',
				confirmPwd: '',
			},
			formRules: {
				oldPwd: [ruleRequired('请输入原始密码'), ruleMaxLength(18)],
				newPwd: [
					ruleRequired('请输入密码'),
					ruleMinLength(6),
					ruleMaxLength(18, '密码长度'),
					ruleComplexPassValidate(),
				],
				confirmPwd: [
					ruleRequired('请输入密码'),
					ruleMinLength(6),
					ruleMaxLength(18, '密码长度'),
					{ validator: equalPassValidate, trigger: '' },
				],
			},
			showOldPwd: false,
			showNewPwd: false,
			showConfirmPwd: false,
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'userInfo',
		}),
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
	},
	watch: {
		show(val) {
			if (!val) {
				this.form = this.$options.data().form
				this.$refs.form?.resetFields()
			}
		},
		'form.oldPwd'() {
			this.form.oldPwd = this.form.oldPwd.replace(/[\u4E00-\u9FA5]/g, '')
		},
		'form.newPwd'() {
			this.form.newPwd = this.form.newPwd.replace(/[\u4E00-\u9FA5]/g, '')
		},
		'form.confirmPwd'() {
			this.form.confirmPwd = this.form.confirmPwd.replace(/[\u4E00-\u9FA5]/g, '')
		},
	},
	methods: {
		// 密码不支持汉字，去除汉字
		handlePwdChange(key) {
			this.form[key] = this.form[key].replace(/[\u4e00-\u9fa5]/g, '')
		},
		handleOk() {
			let flag = false
			this.$refs.form.validate(valid => {
				flag = valid
			})
			if (!flag) return
			const { oldPwd, newPwd, confirmPwd } = this.form
			if (newPwd !== confirmPwd) {
				this.$message.error('两次输入密码不一致!')
				return
			}

			let info = null
			if (Object.keys(this.loginInfo).length > 0) {
				info = this.loginInfo //登录需要修改密码，暂未存值，取接口结果值
			} else {
				info = this.userInfo
			}
			const api = info?.userLevel === 0 || info?.isCrossDomain ? apiModifyAdminPwd : apiModifyTenantPwd
			const params = {
				newPwd: encrypt(newPwd),
				oldPwd: encrypt(oldPwd),
			}
			console.log(this.loginInfo, 'loginInfo')
			api(
				params,
				Object.keys(this.loginInfo).length > 0
					? {
							headers: {
								accessToken: this.loginInfo.accessToken,
							},
					  }
					: {},
			)
				.then(() => {
					this.$message.success('修改密码成功！')
					this.visible = false
					this.$emit('change-success')
				})
				.catch(err => {
					console.error(err)
				})
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.el-input__suffix-inner .iconfont {
		font-size: 22px;
		cursor: pointer;
	}
}
.warning-tip {
	padding: 0 17px;
	margin: 0 auto 8px;
	width: 432px;
	height: 30px;
	background: #ffebe9;
	border-radius: 16px;
	font-size: 10px;
	color: #ec6b60;
	line-height: 30px;
	box-sizing: border-box;
	span {
		font-size: 16px !important;
	}
}
</style>
