import Vue from 'vue'
import _ from 'lodash'
import dayjs from 'dayjs'

import confirm from './plugins/confirm'
import message from './plugins/message'
import notify from './plugins/notify'
import toast from './plugins/toast'
import toFixed from './plugins/toFixed'

import { has } from '@/utils/business'

Vue.prototype._ = _
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
Vue.prototype.dayjs = dayjs

Vue.prototype.$confirm = confirm
Vue.prototype.$message = message
Vue.prototype.$notify = notify
Vue.prototype.$toast = toast
Vue.prototype.$has = has

Number.prototype.toFixed = toFixed
String.prototype.toFixed = toFixed
