import Vue from 'vue'
import VueRouter from 'vue-router'
import { asyncRoutes } from './router.config'

Vue.use(VueRouter)

// 解决路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

const createRouter = () =>
	new VueRouter({
		scrollBehavior: () => ({ y: 0 }),
		routes: [
			{
				path: '/login',
				component: () => import('@/pages/login'),
			},
			...asyncRoutes,
		],
	})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher
}

export default router
