import request from '@/utils/request'

// 新增设备类型
export const apiAddDeviceType = data => {
	return request({
		url: '/monitor/deviceType',
		method: 'POST',
		data,
	})
}

// 查询设备大类列表
export const apiGetFirstCategory = params => {
	return request({
		url: '/monitor/product-category/list/first',
		method: 'GET',
		params,
	})
}

// 查询设备小类列表
export const apiGetSecondCategory = params => {
	return request({
		url: `/monitor/product-category/list/${params.productCategoryId}`,
		method: 'GET',
	})
}

//查询厂商列表
export const apiGetManufacturer = data => {
	return request({
		url: '/monitor/manufacturer/list',
		method: 'POST',
		data,
	})
}

//查询产品列表
export const apiGetProduct = params => {
	return request({
		url: '/monitor/product/list',
		method: 'GET',
		params,
	})
}

// 查询产品的设备类型属性集合
export const apiDtAttribute = params => {
	return request({
		url: `/monitor/deviceType/dtAttribute/${params.productId}`,
		method: 'GET',
	})
}

// 设备类型列表查询
export const apiGetDeviceTypeList = data => {
	return request({
		url: '/monitor/deviceType/info-list',
		method: 'POST',
		data,
	})
}

// 获取租户已授予设备类型列表
export const apiDeviceTypeList = params => {
	return request({
		url: '/monitor/deviceType/tenant/device-type',
		method: 'get',
		params,
		needJointIsolation: true,
	})
}

// 获取已授予和已撤销的设备类型列表
export const apiAllDeviceTypeList = params => {
	return request({
		url: '/monitor/deviceType/list',
		method: 'GET',
		params,
		pending: true,
	})
}

// 设备类型详情查询
export const apiDeviceTypeDetail = params => {
	return request({
		url: `/monitor/deviceType/${params.deviceTypeId}`,
		method: 'GET',
	})
}

// 设备类型修改
export const apiEditDeviceType = data => {
	return request({
		url: '/monitor/deviceType',
		method: 'PUT',
		data,
	})
}
