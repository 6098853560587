<template>
	<!-- tab分页组件 -->
	<div class="gc-detail-tab">
		<el-tabs :tab-position="tabPosition" v-model="activeName" @tab-click="handleClick">
			<template v-for="item in filteredList">
				<el-tab-pane :key="item.name" :label="item.label" :name="item.name" :disabled="item.disabled">
					<keep-alive>
						<!-- 'tab-data':tab页下面的组件入参 -->
						<!-- 在使用时需要在自己声明的组件里接收一下 -->
						<component
							:tab-data="item.data || {}"
							:cur-tab-name="activeName"
							v-bind:is="item.component"
							v-on="$listeners"
							v-bind="$attrs"
						></component>
					</keep-alive>
				</el-tab-pane>
			</template>
		</el-tabs>
	</div>
</template>

<script>
export default {
	name: 'GcDetailTab',
	components: {},
	props: {
		//  tab渲染数据
		/**
		 * tabList[number]:{
		 *    label tab的标签
		 *    name tab标签的name值，用于动态引入需要渲染的组件
		 *    data tab的标签专属组件的入参
		 *    noComp 当前tab是否没有专属组件
		 *    component 当前tab渲染的组件
		 * }
		 * */
		tabList: {
			type: Array,
			default: () => [],
		},
		defaultActiveName: String,
		tabPosition: {
			type: String,
			default: 'top',
		},
	},
	data() {
		return {
			isNoComp: false, // 当前tab的是否展示自定义组件内容
			curTabIndex: '', // 当前tab的索引
		}
	},
	computed: {
		filteredList() {
			return this.tabList.filter(item => this.checkPermission(item.keyWord) && !item.needHide)
		},
		activeName: {
			get: function () {
				return this.defaultActiveName || this.filteredList[0].name
			},
			set: function (val) {
				this.$emit('update:default-active-name', val)
			},
		},
		needTagList() {
			return this.tabList.filter(item => item.needTag)
		},
	},
	watch: {
		needTagList: {
			deep: true,
			immediate: true,
			handler(newVal, oldVal) {
				if (oldVal && oldVal.length) {
					oldVal.forEach(item => {
						this.$nextTick(() => {
							let span = document.getElementById(`span-${item.name}`)
							document.getElementById(`tab-${item.name}`).removeChild(span)
						})
					})
				}
				if (newVal && newVal.length) {
					newVal.forEach(item => {
						this.$nextTick(() => {
							let span = document.createElement('span')
							span.innerHTML = item.tagNum
							span.setAttribute('id', `span-${item.name}`)
							span.setAttribute(
								'style',
								` display: inline-block;
                  position:absolute;
                  top:12px;
                  right:18px;
                  font-size:12px;
                  color:#fff;
                  line-height:16px;
                  text-align:center;
                  min-width:16px;
                  height:16px;
                  border-radius:8px;
                  padding:0 4px;
                  background-color:#E5662E;
                  transform:translateX(100%)`,
							)
							document.getElementById(`tab-${item.name}`).appendChild(span)
						})
					})
				}
			},
		},
		filteredList(val) {
			if (val.length === 1) {
				const firstUpperCase = str => {
					return str.toLowerCase().replace(/( |^)[a-z]/g, L => L.toUpperCase())
				}
				const sizeName = ['top', 'bottom'].indexOf(this.tabPosition) !== -1 ? 'width' : 'height'

				let elTabBar = document.querySelector('.el-tabs__active-bar')
				let id = `tab-${val[0].name}`
				let nextSibling = document.getElementById(id)
				const tabStyles = window.getComputedStyle(nextSibling)
				let tabSize = nextSibling[`client${firstUpperCase(sizeName)}`]
				tabSize -= parseFloat(tabStyles.paddingLeft) + parseFloat(tabStyles.paddingRight)
				elTabBar.style[sizeName] = tabSize + 'px'
			}
		},
	},
	methods: {
		handleClick(tab) {
			this.curTabIndex = tab.index
			this.isNoComp = this.tabList[this.curTabIndex].noComp ? true : false
		},
		checkPermission(val) {
			return this.$store.getters?.permissions.includes(val) || !val
		},
	},
}
</script>
<style lang="scss" scoped>
.gc-detail-tab {
	background-color: #eceff8;
	width: 100%;
	height: 100%;
	::v-deep .el-tabs__nav-scroll {
		font-size: 14px;
		overflow: hidden;
		border: 1px solid #fff;
		background-color: #fff;
		border-bottom: none;
		.el-tabs__item {
			color: #8795a9;
			font-size: 16px;
			&.is-top:last-child {
				padding-right: 20px;
			}
			&.is-top:nth-child(2) {
				padding-left: 20px;
			}
		}
		.is-active {
			color: #4d6bff;
		}
		.is-disabled {
			color: #b3b3b3;
			cursor: not-allowed;
		}
	}
	::v-deep .el-tabs__nav-wrap {
		margin-bottom: 0;
		&.is-scrollable {
			background-color: #fff;
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background-color: transparent;
			z-index: 1;
		}
	}
	::v-deep .el-tabs__content {
		height: calc(100% - 42px);
		background-color: #fff;
		.el-tab-pane {
			height: 100%;
			// overflow: auto;
		}
	}
	.el-tabs.el-tabs--top {
		height: 100%;
	}
	// 隐掉tab的边框
	::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	::v-deep .el-tabs__header {
		margin-bottom: 0;
		z-index: 999;
		border-bottom: 1px solid #f1f1f1;
		.el-tabs__active-bar {
			height: 4px;
			background: #4d6bff;
			border-radius: 100px 100px 0px 0px;
		}
	}
}
</style>
