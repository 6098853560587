<template>
	<div
		:class="['gc-custom-search', { disabled, required, 'show-import': showImportBtn }]"
		:style="{
			width: width || '600px',
			'padding-right': $slots['icon'] ? '24px' : '0',
			...{ customStyle },
		}"
	>
		<div
			class="left"
			v-if="keyWordValue"
			:style="{
				width: keyWordOption.length ? keyWordWidth || '80px' : keyWordWidth || 'auto',
			}"
		>
			<el-select v-if="keyWordOption.length" v-model="keyWordValue" :placeholder="placeholder || '请选择'">
				<el-option v-if="needAllForKeyWord" :value="null" label="全部"></el-option>
				<el-option
					v-for="item in keyWordOption"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				></el-option>
			</el-select>
			<span v-else>{{ keyWordValue }}</span>
		</div>
		<div :class="['content', { 'date-time': classDateTime }]">
			<el-date-picker
				v-if="type === 'date'"
				v-model="searchValue"
				:range-separator="classDateTime ? '' : '至'"
				:type="datePickerType"
				:start-placeholder="`开始${datePlaceholder}`"
				:end-placeholder="`结束${datePlaceholder}`"
				:disabled="disabled"
				:clearable="clearable"
				:default-time="datePickerType === 'datetimerange' ? ['00:00:00', '23:59:59'] : ''"
				:picker-options="pickerOptions"
				:value-format="valueFormat"
				:format="format"
			></el-date-picker>
			<el-select
				v-else-if="type === 'select'"
				v-model="searchValue"
				:placeholder="placeholder || '请选择'"
				:disabled="disabled"
				:clearable="selectClearable"
				:filterable="filterable"
			>
				<el-option v-if="needAllForSearch" :value="null" label="全部"></el-option>
				<el-option
					v-for="item in searchOption"
					:key="item[searchOptionKey.value]"
					:label="item[searchOptionKey.label]"
					:value="item[searchOptionKey.value]"
				></el-option>
			</el-select>
			<el-input-number
				style="width: 100%"
				v-else-if="type === 'input-number'"
				v-model="searchValue"
				:controls="false"
				:placeholder="placeholder || '请输入检索关键字'"
				:disabled="disabled"
				@keyup.enter.native.stop="searchValue = searchValue.replace(/[^\d]/g, '')"
			></el-input-number>
			<el-cascader
				v-else-if="type === 'cascader'"
				:placeholder="placeholder || '请选择'"
				v-model="searchValue"
				:options="searchOption"
				:clearable="clearable"
				:props="searchOptionKey"
				:show-all-levels="false"
			></el-cascader>
			<gc-tree-select
				v-else-if="type === 'tree-select'"
				v-model="searchValue"
				:show-checkbox="showCheckbox"
				:placeholder="placeholder || '请选择'"
				:props="propsObj"
				:options="searchOption"
				:treeSelectClearable="treeSelectClearable"
			></gc-tree-select>
			<gc-number-range v-else-if="type === 'input-range'" v-model="searchValue"></gc-number-range>
			<el-input
				v-else
				v-model="searchValue"
				:placeholder="placeholder || '请输入检索关键字'"
				:disabled="disabled"
				:readonly="readonly"
				@keyup.enter.native.stop="handleKeyupEnter"
			></el-input>
			<el-tag
				style="height: 22px; line-height: 20px; margin-right: 6px"
				v-if="showImportBtn && isArray(searchValue) && searchValue.length"
				size="small"
				closable
				@close="$emit('update:search', '')"
			>
				已导入{{ searchValue.length }}条数据
			</el-tag>
			<el-upload
				class="upload-demo"
				action="###"
				:multiple="false"
				:show-file-list="false"
				accept=".xlsx,.xls"
				:auto-upload="false"
				:on-change="handleChangeFile"
				style="margin-left: auto"
			>
				<el-button v-if="showImportBtn" type="primary">导入</el-button>
			</el-upload>
		</div>
		<div class="unit"><slot name="unit"></slot></div>
		<div class="right">
			<slot name="icon"></slot>
		</div>
	</div>
</template>

<script>
import { isArray } from '@/utils/validate'
const XLSX = require('xlsx')
export default {
	name: 'GcCustomSearch',
	components: {},
	props: {
		customStyle: {
			type: Object,
			default: () => {},
		},
		type: String,
		width: String,
		keyWord: String,
		keyWordWidth: String,
		keyWordOption: {
			type: Array,
			default: () => [],
		},
		search: {
			type: [Array, String, Number, Date],
		},
		searchOption: {
			type: Array,
			default: () => [],
		},
		// 下拉取值key
		searchOptionKey: {
			type: Object,
			default: () => {
				return {
					label: 'label',
					value: 'value',
				}
			},
		},
		datePickerType: {
			type: String,
			default: 'daterange',
		},
		format: String,
		valueFormat: String,
		pickerOptions: {
			type: Object,
			default: () => {},
		},
		placeholder: {
			type: String,
			default: '',
		},
		needAllForKeyWord: {
			type: Boolean,
			default: false,
		},
		needAllForSearch: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		classDateTime: {
			type: Boolean,
			default: false,
		},
		selectClearable: {
			type: Boolean,
			default: false,
		},
		propsObj: {
			type: Object,
			default: () => {},
		},
		treeSelectClearable: {
			type: Boolean,
			default: false,
		},
		filterable: {
			type: Boolean,
			default: false,
		},
		showCheckbox: {
			type: Boolean,
			default: false,
		},
		showImportBtn: {
			type: Boolean,
			default: false,
		},
		importKeys: {
			type: Array,
			default: () => ['设备编号', '前置版本号', '目标版本号'],
		},
	},
	data() {
		return {}
	},
	computed: {
		keyWordValue: {
			get() {
				return this.keyWord
			},
			set(val) {
				this.$emit('update:key-word', val)
			},
		},
		searchValue: {
			get() {
				if (isArray(this.search) && this.showImportBtn) {
					return this.search
						.map(o => o['设备编号'])
						.filter(Boolean)
						.join(',')
				}
				return this.search
			},
			set(val) {
				this.$emit('update:search', val)
			},
		},

		datePlaceholder() {
			return this.datePickerType == 'datetime' || this.datePickerType == 'datetimerange' ? '时间' : '日期'
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		isArray,
		handleKeyupEnter() {
			this.$emit('on-keyup-enter')
		},

		handleChangeFile(file) {
			const { name, size, raw } = file
			if (!/\.(xls|xlsx)$/.test(name.toLowerCase())) {
				this.$message.error('上传文件格式不正确，请上传xls或xlsx格式文档')
				return false
			}
			if (size / 1024 / 1024 > 2) {
				this.$message.error('上传文件大小不得大于2M')
				return false
			}
			const fileReader = new FileReader()
			fileReader.onload = ev => {
				try {
					const workbook = XLSX.read(ev.target.result, {
						type: 'binary',
					})
					const sheetNames = workbook.SheetNames
					const ws = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], {
						defval: null,
					})
					const keys = Object.keys(ws[0] || {})
					if (JSON.stringify(keys.sort()) !== JSON.stringify(this.importKeys.sort())) {
						return this.$message.error('上传的excel格式不正确，请使用模板上传数据')
					}
					if (ws?.length > 5000) {
						return this.$message.error('最多只能同时上传5000条数据')
					}
					const data = ws.map(m => {
						const t = {}
						this.importKeys.forEach(k => {
							t[k] = m[k]
						})
						return t
					})
					this.$emit('update:search', data)
				} catch {
					this.$message.error('excel读取发生错误，请重新读取')
				}
			}
			fileReader.readAsBinaryString(raw)
		},
	},
}
</script>
<style lang="scss" scoped>
$base-font-size: 14px;
.disabled {
	background-color: #f5f7fa;
}
.gc-custom-search {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 11px 0 11px 12px;
	border: 1px solid #eee;
	border-radius: 6px;
	&.show-import {
		max-height: 40px;
	}
	::v-deep .left {
		padding-right: 12px;
		color: #666;
		flex-shrink: 0;
		border-right: 1px solid #eee;
		.el-input--small,
		.el-input--suffix {
			.el-input__inner {
				display: -webkit-box;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 14px;
				line-height: 14px;
				padding: 0 20px 0 0;
				border: 1px solid transparent;
			}
			.el-input__icon {
				line-height: 14px;
				width: 15px;
			}
			.el-select .el-input.is-focus .el-input__inner {
				border-color: transparent;
			}
		}
	}
	::v-deep .content {
		flex: 1;
		width: 0;
		padding-left: 12px;
		display: flex;
		align-items: center;
		&:has(.el-date-editor--date) {
			padding-left: 0;
		}
		.el-button {
			padding: 4px 10px;
			height: auto !important;
			font-size: 12px !important;
			border-radius: 4px;
		}
		.el-date-editor {
			padding-left: 36px;
			.el-input__prefix {
				top: 1px;
			}
		}
		.el-range-editor--small {
			position: relative;
			.el-range-separator {
				width: 10%;
				line-height: 16px;
				font-size: $base-font-size;
				color: #cccccc;
				padding: 0;
			}
			.el-range__close-icon,
			.el-icon-circle-close:before {
				display: inline-block;
				line-height: 16px;
			}
			.el-range__icon {
				display: none;
			}
		}
		.el-input__inner {
			height: 16px;
			border: 1px solid transparent;
			padding: 0;
			width: 100%;
		}
		.el-input--small,
		.el-input--suffix {
			.el-input__inner {
				display: -webkit-box;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 16px;
				line-height: 16px;
				padding: 0 20px 0 0;
				border: 0px solid transparent;
			}
			.el-input__icon {
				line-height: 14px;
			}
		}
		.el-cascader--small {
			line-height: 16px;
		}

		.el-select {
			width: 100%;
			.el-input.is-focus .el-input__inner {
				border-color: transparent;
			}
		}

		.gc-number-range {
			border: none;
			.el-input__inner {
				padding: 0;
			}
		}
	}
	::v-deep .date-time {
		.el-input__inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			height: auto;
		}
		& input:nth-of-type(1) {
			padding-bottom: 18px;
			height: 32px;
		}
		.el-range-input {
			display: inline-block;
			width: 100%;
			height: 14px;
			text-align: left;
		}
		.el-input__icon {
			height: 0;
		}
		.el-range__close-icon {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -7px;
		}
	}
	.unit {
		padding-right: 10px;
		color: #999;
		font-size: 14px;
	}
	.right {
		position: absolute;
		right: 5px;
		font-size: 14px;
		text-align: center;
		width: 25px;
		color: #b8bcc3;
	}
	&.required {
		&::before {
			position: absolute;
			display: inline-block;
			font-size: 14px;
			content: '*';
			color: red;
			top: 55%;
			left: 5px;
			transform: translateY(-50%);
		}
	}
}
</style>
