<template>
	<div id="app">
		<keep-alive include="BigScreen,Layout">
			<router-view />
		</keep-alive>
	</div>
</template>

<script>
import { isEtbcTenant } from '@/utils'
import { apiEtbcLogin } from '@/apis/login.api'
import { mapActions } from 'vuex'

export default {
	name: 'App',
	created() {
		if (isEtbcTenant) {
			this.login({ apiFn: apiEtbcLogin })
		}
	},
	methods: {
		...mapActions({
			login: 'user/login',
		}),
	},
}
</script>
