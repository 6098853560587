import { apiGetcurrentTenant } from '@/apis/login.api'
import { apiGetTenantDetail, apiGetTenantList } from '@/apis/tenant.api'
import { apiSwitchTenant } from '@/apis/login.api'
import store from '@/store'

/**
 * 根据租户id查询租户的详细信息
 * @param {*} tenantId
 */
async function findTenantDetail(tenantId) {
	const data = await apiGetTenantDetail(tenantId)
	store.commit('user/UPDATE_SELECT_TENANT_INFO', {
		tenantId: data.id,
		tenantName: data.name,
	})
	const userInfo = store.state.user.userInfo
	store.commit('user/SET_USER_INFO', {
		...userInfo,
		isOrgEnable: data.orgEnable,
		tenantId: data.id,
	})
}

/**
 * 首次进入没有选择租户时查询租户列表默认选中第一个租户
 */
async function defaultAssignTenant() {
	const { dataList } = await apiGetTenantList({ page: 0, size: 10 })
	if (dataList?.length) {
		const [tenantId, tenantName, orgEnable] = [dataList[0]?.id, dataList[0]?.name, dataList[0].orgEnable]
		store.commit('user/UPDATE_SELECT_TENANT_INFO', {
			tenantId,
			tenantName,
		})
		const userInfo = store.state.user.userInfo
		store.commit('user/SET_USER_INFO', {
			...userInfo,
			isOrgEnable: orgEnable,
			tenantId,
		})
		apiSwitchTenant({ tenantId })
	}
}

export default async function () {
	const data = await apiGetcurrentTenant()
	if (data?.tenantId) {
		await findTenantDetail(data.tenantId)
	} else {
		await defaultAssignTenant()
	}
}
