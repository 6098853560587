// 定义常量

// 设备详情页权限集合
export const deviceDetailPermissions = [
	'monitor:device:account:maintain',
	'monitor:device:account:select',
	'monitor:device:account:command:insert',
	'monitor:device:account:record:alarm',
	'monitor:device:account:record:command',
	'monitor:device:account:record:collect',
	'monitor:device:account:record:operate',
	'monitor:device:account:record:communicate',
]

// 客户类型
export const userType = [
	{ defaultValue: 1, name: '工商' },
	{ defaultValue: 2, name: '居民' },
]

// 设备大类
export const firstCategoryMap = {
	'-1': {
		name: '全部',
		icon: 'all',
		en: 'All The Equipment',
	},
	'0311': {
		name: '自闭阀',
		icon: 'self-closing-valve',
		en: 'Self Closing Valve',
	},
	'0401': {
		name: '计量仪表',
		icon: 'metering-device',
		en: 'Metering Device',
	},
	'0409': {
		name: '自动化设备',
		icon: 'automation-equipment',
		en: 'Automation Equipment',
	},
	'0410': {
		name: '燃气用具',
		icon: 'ranqizao',
		en: 'Gas Appliance',
	},
	'0909': {
		name: '泄露监测设备',
		icon: 'leakage-monitoring-equipment',
		en: 'Leakage Monitoring Equipment',
	},
	9999: {
		name: '其他设备',
		icon: 'other-equipment',
		en: 'Other Equipment',
	},
}

/**
 * 批量导入表头模型
 * @param {string} attributeKey 关键字
 * @param {string} name 名称
 * @param {string} unit 国际单位
 * @param {string} valueType 数值类型 0：字符串 1：时间格式 2：枚举 3：时间戳
 * @param {integer|object} optional 可选配置 0 可选 1必填，对象{linkKey: "关联字段", linkValue: "关联字段值"} 匹配关联字段时为必填
 * @param {integer|array(2)} lengthCheck 长度限制，数值为默认最大长度，数组[最小长度，最大长度]
 * @param {object} inputCheck 输入校验限制 required（必填）、pattern（正则）
 */
export const batchImportThead = (tenantType, deviceTypeId) => {
	// tenantType 租户类型
	let obj = {
		// 基本属性
		baseAttrs: [
			{
				attributeKey: 'deviceNo',
				name: '设备编号',
				optional: 1,
				inputCheck: {
					pattern: /^\d|\$*$/,
					message: '设备编号只能输入数字和$字符',
				},
				lengthCheck: 32,
			},
		],
		// 安装属性
		installAttrs: [
			{
				attributeKey: 'installDate',
				name: '安装日期',
				optional: 1,
				valueType: '1',
			},
			{
				attributeKey: 'installLocation',
				name: '安装位置',
				optional: 0,
				inputCheck: {
					pattern: [906, 811].includes(Number(deviceTypeId))
						? /^(井内|其他)$/
						: /^(不确定|厨房|阳台|过道|车间|进口|出口|井内|立箱)$/,
					message: [906, 811].includes(Number(deviceTypeId))
						? '安装位置填写不正确，只能为井内、其他'
						: '安装位置填写不正确，只能为不确定、厨房、阳台、过道、车间、进口、出口、井内、立箱',
				},
				case: [906, 811].includes(Number(deviceTypeId))
					? '井内、其他'
					: '不确定（或厨房、阳台、过道、车间、进口、出口、井内、立箱）',
			},
			{
				attributeKey: 'userNo',
				name: '用户号',
				optional: 0,
				lengthCheck: 128,
				case: 'U1000001',
			},
			{
				attributeKey: 'userType',
				name: '客户类型',
				optional: 1,
				inputCheck: {
					pattern: /^(工商|居民)$/,
					message: '客户类型只能填写工商或居民',
				},
				case: '居民（或工商）',
				tenantType: '0',
			},
			{
				attributeKey: 'userType',
				name: '客户类型',
				optional: 1,
				inputCheck: {
					pattern: /^(居民表|工商表|考核表|流量计)$/,
					message: '客户类型只能填写居民表、工商表、考核表、流量计',
				},
				case: '居民表（或工商表、考核表、流量计）',
				tenantType: '1',
			},
			{
				attributeKey: 'username',
				name: '客户/站点名称',
				optional: 1,
				lengthCheck: 128,
				case: '金先生',
			},
			{
				attributeKey: 'userPhone',
				name: '联系电话',
				optional: {
					// 当前客户类型为居民时必填
					linkName: '客户类型',
					linkKey: 'userType',
					linkValue: tenantType == 1 ? '居民表' : '居民',
				},
				inputCheck: {
					pattern: /^1[3456789]\d{9}$/,
					message: '输入手机号格式不对，11位且13-19开头',
				},
				case: '18888888888',
			},
			{
				attributeKey: 'homeType',
				name: '是否独居老人',
				optional: 0,
				inputCheck: {
					pattern: /^(是|否)$/,
					message: '是否独居老人请填是或否',
				},
				case: '是（或否）',
				tenantType: '1',
			},
			{
				attributeKey: 'startMeterReading',
				name: '初始表底',
				optional: 1,
				inputCheck: {
					pattern: /^\d*$/,
					message: '设备编号只能输入数字',
				},
				case: '0',
				tenantType: '1',
			},
			{
				attributeKey: 'addressPollName',
				name: '街道/小区/乡镇/村庄',
				optional: 0,
				lengthCheck: 64,
				case: 'xx小区',
			},
			{
				attributeKey: 'address',
				name: '详细地址',
				optional: 1,
				lengthCheck: 128,
				case: 'x幢x单元xx室',
			},
		],
	}
	Object.keys(obj).forEach(key => {
		obj[key] = obj[key].filter(item => !item.tenantType || item.tenantType == tenantType)
	})
	return obj
}

/**
 * 特殊指令交互判断：展示对应修改标识位选项
 * NBG2安全表设置持续用气参数
 * NBG2安全表设置微小流参数
 */
export const cmdModelIds = ['S8006-continuousGasFunctionParms-all', 'S8006-microFlowParms']

export const firmwareTypeOptions = [
	{ label: '主控板', value: 1 },
	{ label: '通讯板', value: 2 },
]

export const firmwareTypeMap = firmwareTypeOptions.reduce((prev, cur) => {
	prev[cur.value] = cur.label
	return prev
}, {})

export const taskStatusOptions = [
	{ label: '执行中', value: 1 },
	{ label: '完成', value: 2 },
	{ label: '截止', value: 3 },
	{ label: '人工取消', value: 4 },
]

export const subTaskStatusOptions = [
	{ label: '执行中', value: 1 },
	{ label: '已完成', value: 2 },
	{ label: '已取消', value: 3 },
]

export const subTaskStatusMap = {
	1: '执行中',
	2: '已完成',
	3: '已取消',
}

export const vertifyStatusOptions = [
	{ label: '待验证', value: 1 },
	{ label: '验证成功', value: 2 },
	{ label: '验证失败', value: 3 },
]

export const vertifyStatusMap = {
	1: '待验证',
	2: '验证成功',
	3: '验证失败',
}

export const downloadStatusOptions = [
	{ label: '待下载', value: 1 },
	{ label: '下载中', value: 2 },
	{ label: '下载成功', value: 3 },
	{ label: '下载失败', value: 4 },
]

export const downloadStatusMap = {
	1: '待下载',
	2: '下载中',
	3: '下载成功',
	4: '下载失败',
}
