<template>
	<div class="gc-number-range">
		<el-input-number
			v-model="startValue"
			:controls="false"
			placeholder="请输入"
			@change="handlerChangeValue($event, 'start')"
		/>
		<span class="gc-range-separator">-</span>
		<el-input-number
			v-model="endValue"
			:controls="false"
			placeholder="请输入"
			@change="handlerChangeValue($event, 'end')"
		/>
	</div>
</template>

<script>
export default {
	name: 'GcNumberRange',
	model: {
		prop: 'value',
		event: 'update:value',
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			startValue: undefined,
			endValue: undefined,
		}
	},
	created() {},

	methods: {
		handlerChangeValue(value, type) {
			if (type === 'start') {
				this.$emit('update:value', [value, this.endValue])
			} else if (type === 'end') {
				this.$emit('update:value', [this.startValue, value])
			}
		},
	},

	watch: {
		value: {
			handler(val) {
				const [start, end] = val || []
				this.startValue = start
				this.endValue = end
			},
			deep: true,
			immediate: true,
		},
	},
}
</script>
<style lang="scss" scoped>
.gc-number-range {
	border: 1px solid #eee;
	border-radius: 8px;
	display: flex;
	align-items: center;
	transition: 0.3s;
	width: 100%;
	.gc-range-separator {
		color: #4c4c4c;
		margin: 0 8px;
	}

	::v-deep .el-input-number {
		flex: 1;
		width: 0;
	}
	::v-deep .el-input {
		width: 100%;
		.el-input__inner {
			border: none;
			height: 16px;
			line-height: 16px;
		}
	}
	&:has(.el-input__inner:focus) {
		border-color: #4d6bff;
	}
}
</style>
