<template>
	<div class="text-tooltip">
		<el-tooltip
			class="text-tooltip-item"
			popper-class="popper-class-custom"
			effect="dark"
			:disabled="isShowTooltip"
			:content="content"
			placement="top-start"
		>
			<p class="over-flow" :style="vauleStyle" @mouseover="onMouseOver(refName)">
				<span :ref="refName">{{ content || content === 0 ? content : '--' }}</span>
			</p>
		</el-tooltip>
	</div>
</template>

<script>
export default {
	name: 'GcCustomTooltip',
	props: {
		// 显示的文字内容
		content: {
			type: [String, Number],
			default: '',
		},
		// 外层框的样式，在传入的这个类名中设置文字显示的宽度
		vauleStyle: {
			type: Object,
			default: () => {},
		},
		// 为页面文字标识（如在同一页面中调用多次组件，此参数不可重复）
		refName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isShowTooltip: true,
		}
	},
	methods: {
		onMouseOver(str) {
			let clientWidthP = this.$refs[str].parentNode.clientWidth
			let scrollWidthP = this.$refs[str].parentNode.scrollWidth
			// 判断是否开启tooltip功能
			if (scrollWidthP > clientWidthP) {
				this.isShowTooltip = false
			} else {
				this.isShowTooltip = true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.over-flow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.wid190 {
	width: 100%;
}
p {
	margin: 0;
}
</style>
