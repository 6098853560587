<template>
	<svg class="icon" aria-hidden="true" :style="{ fontSize: size + 'px' }">
		<use :xlink:href="iconName"></use>
	</svg>
</template>

<script>
export default {
	name: 'GcBaseIcon',
	props: {
		icon: {
			type: String,
			default: '',
		},
		size: {
			type: [String, Number],
			default: '',
		},
	},
	computed: {
		iconName() {
			return '#icon-' + this.icon
		},
	},
}
</script>
<style type="text/css" scoped>
.icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
