<template>
	<div class="tagsViewContainer">
		<router-link
			v-if="affixTag"
			class="affix-tag tags-view-item"
			:key="'affix' + affixTag.fullPath"
			:class="isActive(affixTag) ? 'active' : ''"
			:to="{
				path: affixTag.path,
				query: affixTag.query,
				fullPath: affixTag.fullPath,
			}"
			custom
			v-slot="{ navigate }"
			@contextmenu.prevent.native="openMenu(affixTag, $event)"
		>
			<span @click="navigate" @keypress.enter="navigate" role="link">
				{{ affixTag.title || affixTag.meta.title }}
			</span>
		</router-link>
		<scroll-pane ref="scrollPane" class="tags-view-wrapper" @scroll="handleScroll">
			<router-link
				v-for="tag in allTags"
				ref="tag"
				:key="tag.fullPath"
				:class="isActive(tag) ? 'active' : ''"
				:to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
				class="tags-view-item"
				custom
				v-slot="{ navigate }"
				@contextmenu.prevent.native="openMenu(tag, $event)"
			>
				<span @click="navigate" @keypress.enter="navigate" role="link">
					{{ getTitle(tag) }}
					<span
						v-if="!isAffix(tag) && allTags.length > 1"
						class="el-icon-close gc-icon-close"
						@click.prevent.stop="closeView(tag)"
					/>
				</span>
			</router-link>
		</scroll-pane>
		<el-dropdown
			class="dropdownTagsWrapper"
			@command="handleDropdownItemClick"
			v-if="showDropdownBtn"
			trigger="click"
		>
			<i class="el-icon-arrow-down el-icon--right"></i>
			<el-dropdown-menu slot="dropdown" class="tags-view-dropdown">
				<div class="dropdown-item-wrapper">
					<el-dropdown-item
						v-for="(tag, index) in allTags"
						:key="tag.fullPath"
						:command="index"
						class="vertical-tag"
						:class="isActive(tag) ? 'active' : ''"
					>
						<router-link
							:to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
							tag="span"
							class="tags-view-item"
						>
							<span class="tag-title">{{ tag.title || tag.meta.title }}</span>
							<span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeView(tag)" />
						</router-link>
					</el-dropdown-item>
				</div>
			</el-dropdown-menu>
		</el-dropdown>

		<transition name="move">
			<div
				v-show="showContextMenu"
				:style="{ marginLeft: left + 'px', top: 34 + 'px' }"
				class="context-menu"
				v-click-outside="closeMenu"
			>
				<div class="gc-arrow"></div>
				<div class="menu-list">
					<div class="menu-item closeAll" @click="handleReload">刷新</div>
					<div class="menu-item closeAll" @click="closeToLeft">关闭左侧</div>
					<div class="menu-item closeAll" @click="closeToRight">关闭右侧</div>
					<div class="menu-item closeAll" @click="closeOthers">关闭其它</div>
					<!-- <div class="menu-item closeAll" @click="closeAll">关闭所有</div> -->
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import path from 'path'
import ScrollPane from './ScrollPane'
import vClickOutside from 'v-click-outside'
export default {
	name: 'GcTagsView',
	components: {
		ScrollPane,
	},
	props: {},
	data() {
		return {
			showDropdownBtn: false, // 是否显示下拉框按钮，滚动标签list超出父容器显示
			showContextMenu: false, // 是否显示右键下拉菜单
			top: 0, // 右键下拉菜单边距
			left: 0,
			curTag: {},
		}
	},
	computed: {
		// 所有打开的标签
		allTags() {
			return this.$store.getters.tags
		},
		affixTag() {
			return this.allTags && this.allTags[0] ? this.allTags[0] : {}
		},
		// 所有路由权限
		routes() {
			return this.$store.getters.routes
		},
		// 标签页容器
		scrollContainer() {
			return this.$refs.scrollPane.$el
		},
	},
	watch: {
		$route: {
			handler() {
				this.addTag() // 初始化页面，缓存tags为空时显示
				this.moveToCurrentTag()
			},
			immediate: true,
			deep: true,
		},
		allTags() {
			// 标签页变化，页面重绘后重设下拉按钮显隐
			this.$nextTick(() => {
				this.resetDropdownBtn()
			})
		},
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	mounted() {
		this.initTags()
		this.listenWindowSizeChange()
	},
	methods: {
		getTitle(tag) {
			return tag.title || tag.meta.title
		},
		handleReload() {
			window.location.reload()
		},
		updateRouteAfterDel() {
			this.allTags.length === 1 && this.$router.push(this.allTags[0].fullPath)
			this.closeMenu()
		},
		closeToLeft() {
			this.$store.dispatch('tagsView/closeToLeft', this.curTag).then(() => {
				this.updateRouteAfterDel()
			})
		},
		closeToRight() {
			this.$store.dispatch('tagsView/closeToRight', this.curTag).then(() => {
				this.updateRouteAfterDel()
			})
		},
		closeOthers() {
			this.$store.dispatch('tagsView/closeOthers', this.curTag).then(() => {
				this.updateRouteAfterDel()
			})
		},
		// 关闭所有标签页，留出固定显示的
		closeAll() {
			this.$store.dispatch('tagsView/delAllViews').then(tagList => {
				if (this.affixTags.some(tag => tag.fullPath === this.curTag.fullPath)) {
					return
				}
				this.closeMenu()
				this.toLastView(tagList, this.curTag)
			})
		},
		// 标签页改动，屏幕缩放，重设下拉按钮的显隐
		resetDropdownBtn() {
			if (!this.$refs.tag || this.$refs.tag.length === 0) return
			let width = 0

			this.$refs.tag.map(item => {
				width += item.$el.clientWidth
			})
			this.showDropdownBtn = width > this.scrollContainer.clientWidth
		},
		// 监听页面缩放
		listenWindowSizeChange() {
			window.addEventListener('resize', () => {
				this.resetDropdownBtn()
			})
		},
		// 打开右键下拉菜单
		openMenu(tag, e) {
			this.curTag = tag
			const menuMinWidth = 72
			const offsetLeft = this.$el.getBoundingClientRect().left // tagsView container's margin left
			const offsetWidth = this.$el.offsetWidth // tagsView container width
			const maxLeft = offsetWidth - menuMinWidth // left boundary
			const left = e.clientX - offsetLeft - 60 - 15 // 15: margin right 60: menu width/2

			this.showContextMenu = true
			if (left > maxLeft) {
				this.left = maxLeft
			} else {
				this.left = left
			}
			this.top = e.clientY
		},
		// 手动移动到当前tag，如从浏览器地址栏直接输入地址
		moveToCurrentTag() {
			const tags = this.$refs.tag
			if (!tags || tags.length === 0) return
			this.$nextTick(() => {
				for (const tag of tags) {
					if (tag.to.fullPath === this.$route.fullPath) {
						this.$refs.scrollPane.moveToTarget(tag)
						// 相同路由多开，删除
						// if (tag.to.fullPath !== this.$route.fullPath) {
						//   this.$store.dispatch("tagsView/updateVisitedView", this.$route);
						// }
						break
					}
				}
			})
		},
		// tagsViewContainer 内容滚动，关闭右键下拉框
		handleScroll() {
			this.closeMenu()
		},
		// 关闭右键下拉框
		closeMenu() {
			this.showContextMenu = false
		},
		// 右侧菜单栏选中某一项
		handleDropdownItemClick(index) {
			this.$refs.scrollPane.moveToTarget(this.$refs.tag[index])
		},
		// tag是否是当前路由
		isActive(route) {
			return route.fullPath === this.$route.fullPath
		},
		// 是否是固定显示tag
		isAffix(tag) {
			return tag.meta && tag.meta.affix
		},
		// 关闭某一项
		closeView(view) {
			this.$store.dispatch('tagsView/delView', view).then(tags => {
				if (this.isActive(view)) {
					this.toLastView(tags, view)
				}
			})
		},
		// 新增标签页
		addTag() {
			const { name } = this.$route
			if (name) {
				this.$store.dispatch('tagsView/addView', this.$route)
			}
			return false
		},
		// 筛选出固定在导航栏的tag，待调试
		filterAffixTags(routes, basePath = '/') {
			let tags = []
			routes.forEach(route => {
				if (route.meta && route.meta.affix) {
					const tagPath = path.resolve(basePath, route.path)
					tags.push({
						fullPath: tagPath,
						path: tagPath,
						name: route.name,
						meta: { ...route.meta },
					})
				}
				if (route.children) {
					const tempTags = this.filterAffixTags(route.children, route.path)
					if (tempTags.length >= 1) {
						tags = [...tags, ...tempTags]
					}
				}
			})
			return tags
		},
		// 初始化tags
		initTags() {
			const affixTags = (this.affixTags = this.filterAffixTags(this.routes))
			for (const tag of affixTags) {
				if (tag.name) {
					this.$store.dispatch('tagsView/addView', tag)
				}
			}
			this.resetDropdownBtn()
		},
		// 将最后一个tag置为active
		toLastView(tags, view) {
			const latestView = tags.slice(-1)[0]
			if (latestView) {
				this.$router.push(latestView.fullPath)
			} else {
				if (view.name === 'Dashboard') {
					// to reload home page
					this.$router.replace({ path: '/redirect' + view.fullPath })
				} else {
					this.$router.push('/')
				}
			}
		},
	},
	beforeDestroy() {
		// 移除页面resize监听
		window.addEventListener('resize', () => {
			this.resetDropdownBtn()
		})
	},
}
</script>
<style lang="scss" scoped>
.tagsViewContainer {
	padding-left: 20px;
	position: relative;
	height: 34px;
	background: #ffffff;
	box-shadow: 0px 3px 3px 0px rgba(41, 46, 51, 0.1);

	// .tags-view-wrapper {
	.tags-view-item {
		display: inline-block;
		position: relative;
		cursor: pointer;
		padding: 0 14px;
		height: 34px;
		line-height: 34px;
		font-size: 14px;
		color: #666666;
		background: #ffffff;
		transition: all ease-in-out 0.2s;
		.gc-icon-close {
			padding-left: 12px;
		}
		&:first-of-type {
			// margin-left: 20px;
		}
		&:last-of-type {
			margin-right: 56px;
		}
		&:hover,
		&.active {
			background: #e8f2ff;
			color: #2f87fe;
		}
		&.affix-tag {
			position: absolute;
			left: 20px;
			top: 0;
			z-index: 2;
			margin-right: 0 !important;
		}
	}
	// }
	.tagsWrapper {
		width: 100%;
		overflow-x: scroll;
		.tagList {
			display: flex;
			align-items: center;
			.tagItem {
				flex-shrink: 0;
				padding: 0 14px;
				height: 34px;
				line-height: 34px;
				font-size: 12px;
				font-family: MicrosoftYaHei;
				color: #666666;
			}
		}
	}
	.dropdownTagsWrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: 46px;
		height: 34px;
		text-align: center;
		line-height: 34px;
		z-index: 3;
		background: #fff;
		&:hover,
		&.active {
			background: #e8f2ff;
			color: #2f87fe;
		}
	}
	.context-menu {
		margin: 0;
		background: #fff;
		z-index: 10000;
		position: absolute;
		list-style-type: none;
		border-radius: 4px;
		width: 120px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		border: 1px solid #e6ebf5;
		&.move-enter-to,
		&.move-leave {
			opacity: 1;
			transform: scaleY(1);
		}
		&.move-enter-active,
		&.move-leave-active {
			transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 50ms,
				opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) 50ms;
			transform-origin: center top;
		}
		&.move-leave-to,
		&.move-enter {
			opacity: 0;
			transform: scaleY(0);
		}
		.menu-list {
			padding: 3px 0;
			.menu-item {
				cursor: pointer;
				height: 32px;
				line-height: 32px;
				width: 120px;
				text-align: center;
				font-size: 14px;
				&:hover {
					color: #4e4e4e;
					background: rgba(40, 131, 250, 0.1);
				}
			}
		}

		.gc-arrow {
			position: absolute;
			width: 0px;
			height: 0px;
			border-left: 6px solid transparent;
			border-bottom: 6px solid;
			border-right: 6px solid transparent;

			margin-top: -6px;
			margin-left: 54px;
			border-bottom-color: #e6ebf5;
			z-index: 31;
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 0px;
				height: 0px;
				border-left: 6px solid transparent;
				border-bottom: 6px solid;
				border-right: 6px solid transparent;

				margin-top: 1px;
				margin-left: -6px;
				border-bottom-color: #fff;
				z-index: 32;
			}
		}
	}
}
</style>
<style lang="scss">
//
.tags-view-dropdown {
	.dropdown-item-wrapper {
		max-height: 400px;
		overflow-y: scroll;
	}
}

.vertical-tag {
	padding: 0 10px 0 16px !important;
	box-sizing: border-box;
	width: 120px;
	transition: all ease-in-out 0.2s;
	&:hover,
	&.active {
		background: #e8f2ff;
		color: #2f87fe;
	}
	.tags-view-item {
		height: 32px !important;
		line-height: 32px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		.tag-title {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			word-break: break-all;
		}
	}
}
</style>
