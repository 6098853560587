import request from '@/utils/request'

// 查询告警设备列表
export const apiGetAlarmDeviceList = data => {
	return request({
		url: '/monitor/run/alarm/device/list',
		method: 'POST',
		data,
	})
}

// 查询告警通知记录列表
export const apiGetAlarmNoticeList = data => {
	return request({
		url: '/monitor/run/alarm/notify/list',
		method: 'POST',
		data,
	})
}

// 查询告警记录列表
export const apiGetAlarmRecordsList = data => {
	return request({
		url: '/monitor/run/alarm/list',
		method: 'POST',
		data,
	})
}

// 设备告警处理
export const apiHandleAlarm = data => {
	return request({
		url: '/monitor/ledger/alarm/ack',
		method: 'POST',
		data,
	})
}

// 租户设备类型告警参数查询
export const apiGetAlarmConfig = deviceTypeId => {
	return request({
		url: `/monitor/tenant/alarm/config/${deviceTypeId}`,
		method: 'get',
		needJointIsolation: true,
	})
}

// 租户设备类型告警参数配置
export const apiSetAlarmConfig = data => {
	return request({
		url: '/monitor/tenant/alarm/config',
		method: 'post',
		data,
		needJointIsolation: true,
	})
}

// 租户告警推送策略查询
export const apiGetAlarmPush = params => {
	return request({
		url: '/monitor/tenant/alarm/push',
		method: 'get',
		needJointIsolation: true,
		params,
	})
}

// 租户告警推送策略配置
export const apiSetAlarmPush = data => {
	return request({
		url: '/monitor/tenant/alarm/push',
		method: 'post',
		data,
		needJointIsolation: true,
	})
}

// 异常记录列表
export const apiExceptionList = data => {
	return request({
		url: '/monitor/run/exception/list',
		method: 'POST',
		data,
	})
}

// 异常记录处理登记
export const apiExceptionLog = data => {
	return request({
		url: '/monitor/run/exception/log',
		method: 'POST',
		data,
	})
}

// 获取气量分析设备列表
export const getGasAnalyzList = data => {
	return request({
		url: '/monitor/run/gas-analyze/device/list',
		method: 'POST',
		data,
	})
}

// 单用户时点数据
export const queryStatusByUser = data => {
	return request({
		url: '/monitor/run/gas-analyze/status-by-user',
		method: 'POST',
		data,
	})
}

// 对比分析时点数据
export const queryStatusByDevices = data => {
	return request({
		url: '/monitor/run/gas-analyze/status-by-Devices',
		method: 'POST',
		data,
	})
}

// 计量监控列表
export const apiMetrologicalMonitorList = data => {
	return request({
		url: '/monitor/report/measurement-data/preview',
		method: 'POST',
		data,
	})
}

// 计量监控列表-水务
export const apiWaterMetrologicalMonitorList = data => {
	return request({
		url: '/monitor/report/water-measurement-data/preview',
		method: 'POST',
		data,
	})
}
