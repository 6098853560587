<template>
	<div class="gc-tree-select">
		<el-dropdown trigger="click" placement="bottom-start" @visible-change="visible => (dropdownVisible = visible)">
			<div class="tree-select-area" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
				<div class="select-show-label" :class="{ disabled }">
					<el-input v-model="cnName" :disabled="disabled" :placeholder="placeholder" readonly></el-input>
					<i
						v-if="treeSelectClearable && closeFlag && !isBlank(cnName)"
						class="icon-arrow icon-close el-icon-circle-close"
						@click="handleCloseClick"
					></i>
					<i
						v-else
						class="icon-arrow el-icon-arrow-down"
						:class="{
							'show-drop': dropdownVisible,
						}"
					></i>
				</div>
			</div>
			<el-dropdown-menu slot="dropdown" class="gc-tree-select-drop-down" :style="{ width: `${clientWidth}px` }">
				<div class="drop-down-tree">
					<div class="tree-search-block" v-if="showSearch">
						<el-input v-model="searchValue" placeholder="请输入搜索关键字"></el-input>
					</div>
					<el-tree
						ref="selectTree"
						:node-key="treeProps.id"
						:data="options"
						default-expand-all
						highlight-current
						:expand-on-click-node="false"
						icon-class="el-icon-arrow-right"
						:props="treeProps"
						:filter-node-method="filterNode"
						:indent="12"
					>
						<div
							class="custom-tree-node"
							slot-scope="{ node }"
							:class="{ disabled: isDisabled(node) }"
							@click.stop
						>
							<el-dropdown-item @click.native="handleClickDropItem(node)" :disabled="isDisabled(node)">
								{{ node.label }}
							</el-dropdown-item>
						</div>
					</el-tree>
				</div>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
import { isBlank } from '@/utils/validate'

export default {
	name: 'GcTreeSelect',
	model: {
		prop: 'value',
		event: 'update:value',
	},
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		options: {
			type: Array,
			default: () => [],
		},
		props: {
			type: Object,
			default: () => {},
		},
		disabled: Boolean,
		placeholder: {
			type: String,
			default: '请选择',
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
		treeSelectClearable: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			cnName: '',
			dropdownVisible: false,
			searchValue: '',
			clientWidth: 0,
			treeProps: {
				id: 'id',
				label: 'label',
				children: 'children',
				disabled: 'disabled',
				isLeaf: 'isLeaf',
			},
			closeFlag: false,
		}
	},
	created() {},

	methods: {
		isBlank,
		isDisabled(node) {
			const { disabled } = this.treeProps
			return typeof disabled === 'function' ? disabled(node.data) : node.disabled
		},
		handleClickDropItem(node) {
			this.$emit('update:value', node.data[this.treeProps.id])
		},

		findTargetTreeItemById(id, list) {
			for (let i = 0; i < list.length; i++) {
				if (list[i][this.treeProps.id] === id) {
					return list[i]
				}
				if (list[i].children?.length) {
					const t = this.findTargetTreeItemById(id, list[i].children)
					if (t) return t
				}
			}
		},

		setHighLightNode() {
			const t = this.findTargetTreeItemById(this.value, this.options || [])
			if (t) {
				this.cnName = t[this.treeProps.label]
			} else {
				this.cnName = this.value
			}
			this.$nextTick(() => {
				this.$refs.selectTree.setCurrentKey(t?.[this.treeProps.id] || null)
			})
		},

		filterNode(value, data) {
			if (!value) return true
			return data[this.treeProps.label].indexOf(value) !== -1
		},
		handleMouseMove() {
			this.closeFlag = true
		},
		handleMouseLeave() {
			this.closeFlag = false
		},
		handleCloseClick(e) {
			e.stopPropagation()
			this.cnName = ''
			this.$emit('update:value', null)
		},
	},

	watch: {
		value: {
			handler() {
				this.$nextTick(() => {
					this.setHighLightNode()
				})
			},
			immediate: true,
		},

		options: {
			handler() {
				this.$nextTick(() => {
					this.setHighLightNode()
				})
			},
			immediate: true,
			deep: true,
		},

		searchValue(val) {
			this.$refs.selectTree.filter(val)
		},

		dropdownVisible(val) {
			if (val) {
				this.$nextTick(() => {
					const parentNode = document.querySelector('.gc-tree-select')
					this.clientWidth = parentNode.clientWidth
				})
			}
		},

		props: {
			handler(val) {
				this.treeProps = {
					...this.treeProps,
					...(val || {}),
				}
			},
			deep: true,
			immediate: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.gc-tree-select {
	width: 100%;
	::v-deep .el-dropdown {
		width: 100%;
	}
	.tree-select-area {
		.select-show-label {
			position: relative;
			::v-deep .el-input {
				.el-input__inner {
					cursor: pointer;
				}
			}
			.icon-arrow {
				position: absolute;
				right: 12px;
				top: 50%;
				transition: 0.3s;
				transform: translateY(-50%);
				&.show-drop {
					transform: translateY(-50%) rotate(180deg);
				}
			}
			.icon-close {
				color: #c0c4cc;
			}
			&.disabled {
				.icon-arrow {
					opacity: 0.6;
				}
				::v-deep .el-input {
					.el-input__inner {
						cursor: not-allowed;
					}
				}
			}
		}
	}
}
</style>

<style lang="scss">
.gc-tree-select-drop-down {
	padding: 16px 12px !important;
	max-height: 260px;
	overflow-y: scroll;
	.tree-search-block {
		margin-bottom: 6px;
	}
	.el-tree {
		.el-tree-node {
			&__content {
				height: 36px;
				border-radius: 4px;
				.el-icon-arrow-right:not(.is-leaf) {
					color: #3f435e;
					font-size: 10px;
					font-weight: bold;
				}
				.custom-tree-node {
					flex: 1;
					width: 0;
					align-items: center;
					color: #3f435e;
					font-size: 14px;
					&.disabled {
						cursor: not-allowed;
						opacity: 0.5;
						.el-dropdown-menu__item {
							color: #3f435e !important;
							font-weight: normal !important;
						}
					}
					.el-dropdown-menu__item {
						width: 100%;
						line-height: 36px;
						padding: 0;
						&:hover {
							background-color: transparent;
							color: #4d6bff;
						}
					}
				}
			}
			&.is-current {
				& > .el-tree-node__content {
					.custom-tree-node {
						.el-dropdown-menu__item {
							color: #4d6bff;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
</style>
