<template>
	<div class="gc-sub-title">
		<div class="sub-title-icon" v-if="icon">
			<slot v-if="$slots.icon" name="icon" />
			<svg v-else class="icon" aria-hidden="true">
				<use :xlink:href="`#${icon}`"></use>
			</svg>
		</div>
		<div class="sub-title-text">{{ text }}</div>
		<div class="sub-title-slot">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'GcSubTitle',
	props: {
		icon: {
			default: 'defaultIconUrlForPlacement',
		},
		text: {},
	},
}
</script>
<style lang="scss" scoped>
.gc-sub-title {
	display: flex;
	align-items: center;
	.sub-title-icon {
		margin-right: 12px;
		@include flex-center;
		.icon {
			width: 19px;
			height: 19px;
		}
	}
	.sub-title-text {
		flex: 1;
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		line-height: 24px;
	}
	.sub-title-slot {
	}
}
</style>
