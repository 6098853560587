export default function (n = 0) {
	if (!this || this === 'null') return `0.${'0'.padStart(n, '0')}`
	const value = this * 1 // 值转换，例如 2e3 => 2000
	let [integer, decimal = ''] = value.toString().split('.')
	if (n) {
		decimal = decimal.slice(0, n).padEnd(n, 0)
		return `${integer}.${decimal}`
	}
	return integer
}
