import router from '@/router'
import store from '@/store'
import { getLS, clearLS } from '@/utils/storage'
import { hasPermission } from '@/utils/app'
import { clearPending } from '@/utils/pending'
import notify from '@/global/plugins/notify'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getHasSelectTenant from '@/utils/tenant'

NProgress.configure({
	easing: 'ease',
	speed: 500,
	trickleSpeed: 200,
	showSpinner: false,
})

const getFirstChild = list => {
	if (list[0].children?.length) return getFirstChild(list[0].children)
	return list[0]
}

router.beforeEach(async (to, from, next) => {
	clearPending()
	NProgress.start()
	const token = getLS('token')
	if (!token) {
		if (to.path === '/login') return next()
		next({
			path: '/login',
			query: { redirect: to.fullPath },
		})
	} else {
		const flag = hasPermission(store.getters.permissions, to)
		if (flag && to.matched.length) {
			if (to.path === '/login') {
				next({ ...from, replace: true })
				return NProgress.done()
			}
			const authRoleIds = store.state.user.userInfo?.authRoleIds || []
			// 运维角色登录
			if (authRoleIds.includes('11') || authRoleIds.includes('12')) {
				await getHasSelectTenant()
			}
			next()
		} else {
			if (from.path !== '/') {
				notify('暂无权限访问，请联系管理员！')
			}
			if (store.getters.routes?.length) {
				const { fullPath } = getFirstChild(store.getters.routes)
				next({ path: fullPath, replace: true })
			} else {
				notify('暂无权限访问，请联系管理员！')
				clearLS()
				return next('/login')
			}

			NProgress.done()
		}
	}
})

router.afterEach(() => {
	NProgress.done()
})
