import request from '@/utils/request'

export const apiInitWaterRegion = () => {
	return request({
		url: `/monitor/water/region/initWaterRegion`,
		method: 'GET',
	})
}

export const apiGetWaterRegionTree = (data = {}) => {
	return request({
		url: `/monitor/water/region/waterRegionTree`,
		method: 'POST',
		data,
	})
}

export const apiInsertWaterRegion = data => {
	return request({
		url: `/monitor/water/region/add`,
		method: 'POST',
		data,
	})
}

export const apiUpdateWaterRegion = data => {
	return request({
		url: `/monitor/water/region/update`,
		method: 'POST',
		data,
	})
}

export const apiDeleteWaterRegion = params => {
	return request({
		url: `/monitor/water/region/delete`,
		method: 'GET',
		params,
	})
}
