import Layout from '@/layout'

export default [
	{
		order: 8,
		path: '/systemForTenant',
		component: Layout,
		meta: {
			title: '系统管理',
			subtitle: '系统',
			icon: 'icon-zuzhi',
		},
		redirect: '/systemForTenant/open-account-setting',
		children: [
			{
				path: 'open-account-setting',
				name: 'openAccountSetting',
				meta: {
					title: '开户指令集',
					keepAlive: true,
					permissions: ['monitor:system:init-command-set:config'],
				},
				component: () => import('@/pages/system-for-tenant/open-account-setting/index.vue'),
			},
			{
				path: 'secret-key-manage',
				name: 'secretKeyManage',
				meta: {
					title: '密钥管理',
					keepAlive: true,
					permissions: ['monitor:system:secret-key-manage'],
				},
				component: () => import('@/pages/system-for-tenant/secret-key-manage/index.vue'),
			},
			{
				path: 'region-manage',
				name: 'regionManage',
				meta: {
					title: '区域管理',
					keepAlive: true,
					permissions: ['monitor:waterregion:manage:select'],
				},
				component: () => import('@/pages/system-for-tenant/region-manage/index.vue'),
			},
		],
	},
]
