<template>
	<div class="gc-table-column-setting">
		<el-popover
			placement="bottom-start"
			width="200"
			trigger="click"
			popper-class="column-setting-popover"
			:visible-arrow="false"
			v-model="visible"
		>
			<el-button class="button-setting" style="border-radius: 8px" slot="reference">
				<i class="el-icon-setting"></i>
				设置
			</el-button>
			<div class="column-set-modal">
				<div class="column-list">
					<div class="column-list-item">
						<el-checkbox v-model="checkedAll">全选</el-checkbox>
					</div>
					<draggable
						v-model="columnList"
						:animation="200"
						:touchStartThreshold="5"
						:force-fallback="true"
						handle=".drag-icon"
						draggable=".drag-column-list-item"
						chosen-class="chosen-folder-item"
						drag-class="drag-folder-item"
					>
						<transition-group>
							<div
								class="column-list-item"
								v-for="item in columnList"
								:key="item.key"
								:class="{
									hidden: item.key === 'oprate',
									'drag-column-list-item': !item.dragDisable,
								}"
							>
								<el-checkbox v-model="item.selected" :disabled="item.disabledSelect">
									{{ item.name }}
								</el-checkbox>
								<img
									v-if="!item.dragDisable"
									src="@/assets/imgs/icon/drag-icon.svg"
									class="drag-icon"
									alt=""
								/>
							</div>
						</transition-group>
					</draggable>
				</div>
				<div class="bottom-operate">
					<el-button type="text" @click="resetUpdateColumns">重置</el-button>
					<el-button type="default" @click="cancelUpdateColumns">取消</el-button>
					<el-button type="primary" @click="confirmUpdateColumns">确认</el-button>
				</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { getLS, setLS } from '@/utils/storage'
import draggable from 'vuedraggable'

export default {
	name: 'GcColumnSetting',
	props: {
		columns: {
			type: Array,
			default: () => [],
		},
		storageKey: String,
	},
	components: { draggable },
	data() {
		return {
			checkedAll: false,
			visible: false,
			columnList: [],
		}
	},
	created() {
		this.initTableColumns()
	},

	methods: {
		initTableColumns() {
			const storageColumns = getLS(this.storageKey)
			if (!storageColumns) {
				// 若本地没有缓存直接使用传进来的数据
				this.columnList = this.columns.map((o, index) => {
					return {
						...o,
						selected: o?.selected === false ? false : true,
						sortIndex: index, // 排序字段为了重置columns
					}
				})
			} else {
				// 本地有缓存则使用本地缓存columns 顺序以及选中状态和默认排序字段
				this.columnList = storageColumns.map(o => {
					const t = this.columns.find(i => i.key === o.key)
					return {
						...t,
						selected: o.selected,
						sortIndex: o.sortIndex,
					}
				})
			}
			this.$emit('update-column', this.columnList)
		},

		confirmUpdateColumns() {
			this.$emit(
				'update-column',
				this.columnList.map(o => o),
			)
			this.visible = false
			setLS(this.storageKey, this.columnList)
		},

		cancelUpdateColumns() {
			this.columnList = this.columns.map(o => {
				return {
					...o,
					selected: o.selected !== false ? true : false,
				}
			})
			this.visible = false
		},

		resetUpdateColumns() {
			this.columnList = this.columnList.sort((x, y) => x.sortIndex - y.sortIndex)
			this.columnList.forEach(o => {
				this.$set(o, 'selected', true)
			})
		},
	},

	watch: {
		columns: {
			handler(newVal) {
				this.columnList = newVal.map(o => {
					return {
						...o,
						selected: o.selected !== false ? true : false,
					}
				})
			},
			deep: true,
		},

		checkedAll(checked) {
			if (checked) {
				this.columnList.forEach(o => {
					this.$set(o, 'selected', true)
				})
			}
		},

		visible(newVal) {
			if (newVal) {
				this.checkedAll = false
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.gc-table-column-setting {
	margin-left: auto;
	.button-setting {
		.el-icon-setting {
			margin-right: 4px;
			font-size: 15px;
		}
	}
}
</style>

<style lang="scss">
.column-setting-popover {
	width: auto !important;
	min-width: 220px;
	.column-set-modal {
		padding-left: 8px;
		.column-list {
			&-item {
				display: flex;
				padding: 4px 0;
				align-items: center;
				justify-content: space-between;
				&.hidden {
					display: none;
				}
				.drag-icon {
					width: 8px;
					cursor: move;
					user-select: none;
					margin-left: 6px;
				}
			}
		}
	}
	.bottom-operate {
		display: flex;
		margin-top: 12px;
		.el-button {
			padding: 8px 12px;
			font-size: 12px !important;
			height: auto;
			border-radius: 4px;
			&.el-button--text {
				padding: 0;
				margin-right: auto;
			}
		}
	}
}
</style>
