/**
 * 配置页面动态路由
 * order: Number 路由排序
 * meta : {
    title: String 标题
    icon: String 图标
    permissions: Array<String> 权限列表
    keepAlive: Boolean 设置true进行页面缓存
    hidden: Boolean 设置路由是否在菜单中显示,默认false则展示在导航菜单中,设置true则影藏不展示在菜单中
    hiddenCrumb: Boolean 设置路由在面包屑中隐藏，默认false，设置true则隐藏
    customCrumb: Array<Object> 自定义面包屑  customCrumbTitle 为面包屑名称； customCrumbPath 为对应跳转路由
  }
 */

const files = require.context('./modules', true, /\.js$/)
const routeMap = []
files.keys().map(key => {
	const array = files(key).default || []
	routeMap.push(...array)
})
routeMap.sort((a, b) => a.order - b.order)

export const asyncRoutes = routeMap
