<template>
	<el-dialog
		top="0"
		:custom-class="`gc-dialog-custom ${!isPadding ? 'no-padding' : ''}`"
		:id="dialogId"
		:visible.sync="visible"
		:width="width"
		:close-on-click-modal="false"
		:show-close="showClose"
		:append-to-body="appendToBody"
		:close-on-press-escape="closeOnPressEscape"
		@open="open"
		@close="close"
	>
		<div slot="title" class="title">
			<slot v-if="$slots.icon" name="icon" />
			<svg v-else class="icon" aria-hidden="true">
				<use :xlink:href="`#${icon}`"></use>
			</svg>
			<span class="title-content">{{ title }}</span>
		</div>
		<slot />
		<template v-if="showFooter">
			<div v-if="$slots.footer" slot="footer">
				<slot name="footer" />
			</div>
			<div v-else slot="footer" class="footer">
				<el-button v-if="showCancelBtn" circle @click="handleCancel">
					{{ cancelText }}
				</el-button>
				<el-button circle type="primary" :loading="okLoading" @click="handleOk">
					{{ okText }}
				</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'GcDialog',
	props: {
		// 控制弹框显隐
		show: {
			type: Boolean,
			default: false,
		},
		// 控制关闭图标
		showClose: {
			type: Boolean,
			default: true,
		},
		// 弹框头部图标,可通过设置slot="icon"自定义
		icon: {
			type: String,
			default: 'icon-tongyongdanchuangtubiao',
		},
		// 弹框标题
		title: String,
		// 是否显示底部,默认居中显示"取消"和"确定"按钮，可通过设置slot="footer"自定义
		showFooter: {
			type: Boolean,
			default: true,
		},
		showCancelBtn: {
			type: Boolean,
			default: true,
		},
		// 右侧按钮文案,默认为"提交"
		okText: {
			type: String,
			default: '提 交',
		},
		// 左侧按钮文案,默认为"取消"
		cancelText: {
			type: String,
			default: '取 消',
		},
		// 弹框宽度,默认480px
		width: {
			type: String,
			default: '480px',
		},
		// 弹框body是否需要边距
		isPadding: {
			type: Boolean,
			default: true,
		},
		appendToBody: {
			type: Boolean,
			default: false,
		},
		closeOnPressEscape: {
			type: Boolean,
			default: true,
		},
		okLoading: Boolean,
	},
	watch: {
		show(newVal) {
			if (newVal) {
				this.handleDialogBodyHeight()
			}
		},
	},
	computed: {
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
	},
	data() {
		return {
			top: '120px',
			dialogId: `dialog-${uuidv4()}`,
			timer: '',
		}
	},
	methods: {
		handleOk() {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.$emit('ok')
			}, 500)
		},
		handleCancel() {
			this.$emit('cancel')
		},
		open() {
			// Dialog 打开的回调
			this.$emit('open')
		},
		close() {
			// Dialog 关闭的回调
			this.$emit('close')
		},
		handleDialogBodyHeight() {
			this.$nextTick(() => {
				const clientHeight = document.body.clientHeight
				let dialog = this.querySelector('.gc-dialog-custom')
				let dialogHeader = this.querySelector('.el-dialog__header')
				let dialogBody = this.querySelector('.el-dialog__body')
				let dialogFooter = this.querySelector('.el-dialog__footer')
				let otherHeight = 120
				if (dialogHeader) {
					otherHeight += dialogHeader.offsetHeight
				}
				if (dialogFooter) {
					otherHeight += dialogFooter.offsetHeight
				}
				dialogBody.style.maxHeight = `calc(100vh - ${otherHeight}px)`
				if (clientHeight - dialog.offsetHeight <= 180) {
					this.top = '60px'
				} else {
					this.top = '120px'
				}
			})
		},
		querySelector(el, parent = '#' + this.dialogId) {
			return document.querySelector(`${parent} ${el}`)
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__wrapper {
	background: rgba(34, 34, 34, 0.7);

	+ .v-modal {
		display: none;
	}
}

::v-deep .gc-dialog-custom {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	max-width: 100%;

	.el-dialog__header {
		height: 58px;
		display: flex;
		align-items: center;
		padding: 0;
		padding-left: 15px;
	}

	.title {
		display: flex;
		align-items: center;
		.icon {
			width: 24px;
			height: 24px;
			margin-right: 4px;
		}
		&-content {
			font-size: 18px;
			font-weight: 400;
			color: #4d4d4d;
		}
	}

	.el-dialog__body {
		@include base-scrollbar(4px);
		overflow: auto;
		min-height: 58px;
		max-height: calc(100vh - 232px);
		box-sizing: border-box;
		padding: 0 24px;
	}
	&.no-padding .el-dialog__body {
		padding: 0;
	}

	.el-dialog__footer {
		padding: 0;
		padding-right: 20px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.footer {
			.el-button {
				min-width: 80px;
				height: 32px;
				padding: 0 24px;
				border-radius: 16px;
				font-size: 14px;
				& + .el-button {
					margin-left: 10px;
				}
			}
		}
	}
}
</style>
