//  判断是否为空
export function isBlank(value) {
	return (
		['', null, undefined].includes(value) ||
		value.toString().trim() === '' ||
		value.toString().toLocaleLowerCase().trim() === 'null'
	)
}

// 判断是否是字符串
export function isString(value) {
	return typeof value === 'string' || value instanceof String
}

// 判断是否是数字
export function isNumber(value) {
	return typeof value === 'number' && !isNaN(value)
}

// 判断是否是数组
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]'
	}
	return Array.isArray(arg)
}

// 判断是否为对象
export function isObject(value) {
	return Object.prototype.toString.call(value) === '[object Object]'
}
