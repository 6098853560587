import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import { addPending, removePending } from '@/utils/pending'
import { isBlank } from '@/utils/validate'
import { baseURL } from '@/config'
import { clearLS } from '@/utils/storage'
import { resetRouter } from '@/router'
import { isEtbcTenant, etbcLogout } from '@/utils'

const _this = Vue.prototype

const service = axios.create({
	baseURL,
	timeout: 60000,
	headers: {
		'App-Namespace': 'iot-monitor', // iot监控专用header
	},
})

service.interceptors.request.use(
	config => {
		const { token, userInfo, isolationOrgId, isolationOrgCode } = store.getters
		if (token) config.headers['accessToken'] = token
		if (userInfo?.needPeekIsolation && isolationOrgId && isolationOrgCode && config.needJointIsolation) {
			config.headers['Isolation-Org-Id'] = isolationOrgId
			config.headers['Isolation-Org-Code'] = isolationOrgCode
		}
		removePending(config) // 在请求开始前，对之前的请求做检查取消操作，api中设置 pending: true, 时不检查
		addPending(config) // 将当前请求添加到 pending 中
		return config
	},
	error => {
		return Promise.reject(error)
	},
)

service.interceptors.response.use(
	response => {
		removePending(response.config) // 在请求结束后，移除本次请求
		let { data, code, message, ...args } = response.data || {}
		const { responseType } = response.config
		if (responseType == 'blob') {
			return response.data
		} else if (!isBlank(code) && code == 0) {
			// data:[] 防止被解构
			if (Object.prototype.toString.call(data) == '[object Object]') {
				return Promise.resolve({ ...data, ...args })
			} else {
				if (Object.keys(args).length > 0) {
					// 存在其他参数
					return Promise.resolve({ data, ...args })
				} else {
					return Promise.resolve(data)
				}
			}
		} else {
			_this.$message.error(message || '服务器开小差了~')
			return Promise.reject(response.data)
		}
	},
	error => {
		const { message, response } = error
		if (message.indexOf('REPEAT_REQUEST') > -1) {
			const apiInfo = message.split('&')
			const msg = `重复请求，已取消${apiInfo[2]}`
			return Promise.reject(msg)
		}
		if (!response) return Promise.reject(error)
		const { status, data } = response
		if (status === 401) {
			_this.$notify('账号长时间未登录或在别处登录，请重新登录！')
			if (isEtbcTenant) {
				etbcLogout()
			} else {
				setTimeout(() => {
					clearLS()
					resetRouter()
					location.reload()
				}, 1000)
			}
		} else if (status === 403) {
			_this.$notify('暂无权限，请联系管理员')
		} else {
			_this.$message.error(message)
		}
		return Promise.reject(data?.error || error)
	},
)

export default service
