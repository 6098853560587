// 业务层工具方法

import store from '@/store'
import { isArray, isString } from './validate'

/**
 * 获取字典中对应value值
 * @param {String} dictionaryKey 字典对象对应key值
 * @param {String} key 指定数组中对应key值
 * @returns {String} value
 */
export const getDictionaryValue = (dictionaryKey, key) => {
	if (!dictionaryKey || !key) {
		console.warn('参数不合法')
		return
	}
	const dictionary = store.getters?.dataList || {}
	// eslint-disable-next-line no-prototype-builtins
	if (dictionary.hasOwnProperty(dictionaryKey)) {
		const array = dictionary[dictionaryKey]
		if (!isArray(array)) return key
		const result = array.find(item => item.key == key)
		return result?.value || '--'
	}
	return key
}

/**
 * 权限判断，调用方式 $has("permissionCode") | $has(["permissionCode1", "permissionCode2"])
 * @param {String | Array} value 当前目标权限
 * @returns {Boolean}
 */
export const has = value => {
	if (!value) return false
	const permissions = store.getters?.permissions || []
	if (isArray(value)) {
		return permissions.some(role => value.includes(role))
	} else if (isString(value)) {
		return permissions.includes(value)
	}
	return false
}

/**
 * 站点名称/客户名称 站点编号 安装位置特殊展示条件
 * 金卡NB双路压力采集器 密闭空间可燃气体监测仪
 */
export const siteShow = deviceTypeId => {
	return [906, 811].includes(+deviceTypeId)
}

/**
 * 转换数据字典数据格式(sortName和defaultValue -> label和value)
 * @param {Array} array
 * @returns {Array}
 */
export const transDataList = array => {
	if (!isArray(array)) {
		console.error('请传入数组')
		return array
	}
	const result = array.map(item => {
		return {
			value: item.defaultValue,
			label: item.name,
		}
	})
	return result
}
