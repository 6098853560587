import store from '@/store'
const pressureUnitName = store.getters.pressureUnitName

export const dimensionOptionsGas = [
	{
		key: 'standardCumulativeFlow',
		name: '标况总量',
	},
	{
		key: 'workCumulativeFlow',
		name: '工况总量',
	},
	{
		key: 'instantaneousFlow',
		name: '瞬时',
	},
	{
		key: 'pressure',
		name: '压力',
	},
	{
		key: 'gasTemperature',
		name: '温度',
	},
]

export const dimensionOptionsChannel = [
	{
		key: 'cumulativeFlow',
		name: '累积量',
	},
	{
		key: 'instantaneousFlow',
		name: '瞬时量',
	},
	{
		key: 'pressure',
		name: '压力',
	},
	{
		key: 'gasTemperature',
		name: '温度',
	},
]
export const chartsConfigGas = {
	standardCumulativeFlow: {
		title: '标况总量对比分析',
		unit: 'm³',
	},
	workCumulativeFlow: {
		title: '工况总量对比分析',
		unit: 'm³',
	},
	standardInstantaneousFlow: {
		title: '标况瞬时流量对比分析',
		unit: 'm³',
	},
	workInstantaneousFlow: {
		title: '工况瞬时流量对比分析',
		unit: 'm³',
	},
	pressure: {
		title: '压力对比分析',
		unit: pressureUnitName,
	},
	gasTemperature: {
		title: '温度对比分析',
		unit: '℃',
	},
}
export const chartsConfigChannel = {
	cumulativeFlow: {
		title: '累积量对比分析',
		statusKey: ['workCumulativeFlow', 'standardCumulativeFlow'],
		legend: {
			workCumulativeFlow: '\n\n\n\n\n\n\n\n\n\n\n工\n\n况\n\n累\n\n积\n\n量',
			standardCumulativeFlow: '\n\n\n\n\n\n\n\n\n\n\n标\n\n况\n\n累\n\n积\n\n量',
		},
		unit: 'm³',
	},
	instantaneousFlow: {
		title: '瞬时量对比分析',
		statusKey: ['standardInstantaneousFlow', 'workInstantaneousFlow'],
		legend: {
			workInstantaneousFlow: '\n\n\n\n\n\n\n\n\n\n\n工\n\n况\n\n瞬\n\n时\n\n量',
			standardInstantaneousFlow: '\n\n\n\n\n\n\n\n\n\n\n标\n\n况\n\n瞬\n\n时\n\n量',
		},
		unit: 'm³',
	},
	'pressure-gasTemperature': {
		title: '压力温度对比分析',
		statusKey: ['pressure', 'gasTemperature'],
		legend: {
			pressure: '\n\n\n\n\n\n压\n\n力',
			gasTemperature: '\n\n\n\n\n\n温\n\n度',
		},
		unit: {
			pressure: pressureUnitName,
			gasTemperature: '℃',
		},
	},
	mainVoltage: {
		title: '电压对比分析',
		statusKey: ['mainVoltage'],
		legend: {
			mainVoltage: '\n\n\n\n\n\n电\n\n压',
		},
		unit: 'V',
	},
}

export function channelInfomationChartsChannel(that, chartsConfig, currentCharts) {
	// 标题
	let title = currentCharts.map((key, index) => {
		return {
			text: chartsConfig[key].title.split('').join('\n\n'),
			textStyle: {
				color: '#000',
				fontWeight: 'bolder',
				fontSize: '18',
			},
			left: '0',
			top: (100 / currentCharts.length) * index + 3 + '%',
		}
	})
	// 布局分割
	let grid = currentCharts.map((key, index) => {
		return {
			left: '10%',
			right: '4%',
			top: (100 / currentCharts.length) * index + 3 + '%',
			containLabel: true,
			height: '300px',
		}
	})

	let legend = [] //legend
	let xData = [] // X轴数据
	currentCharts.forEach((key, index) => {
		Object.keys(chartsConfig[key].legend).forEach((ckey, cindex) => {
			legend.push({
				itemWidth: 12,
				itemHeight: 12,
				data: [
					{
						name: chartsConfig[key].legend[ckey].split('\n').join(''),
						icon: 'roundRect',
					},
				],
				left: (cindex + 1) * 3 + '%',
				top: (100 / currentCharts.length) * index + '%',
				formatter: () => {
					return chartsConfig[key].legend[ckey]
				},
				textStyle: {
					color: '#000',
					fontSize: 12,
					padding: [-10, -10, -10, -18],
				},
			})
			if (that.currentDeviceObj.status[ckey] && that.currentDeviceObj.status[ckey].length > 0) {
				xData = that.currentDeviceObj?.status[ckey].map(item => {
					return item.time
				})
			}
		})
	})
	// X轴/y轴
	let xAxis = []
	let yAxis = []
	currentCharts.forEach((key, index) => {
		xAxis.push({
			gridIndex: index,
			type: 'category',
			scale: true,
			axisLabel: {
				show: index == currentCharts.length - 1 ? true : false,
			},
			axisTick: {
				alignWithLabel: true,
			},
			splitLine: {
				show: false,
			},
			data: xData, //x轴时间的数据
		})
		if (Object.prototype.toString.call(chartsConfig[key].unit) == '[object Object]') {
			Object.entries(chartsConfig[key].unit).forEach(([unitKey, unitValue]) => {
				if (unitKey == 'pressure') {
					let pressureData = that.currentDeviceObj?.status['pressure']
						? that.currentDeviceObj?.status['pressure'].map(item => {
								return item.value
						  })
						: []
					let pressureInterval = Math.ceil(Math.max(...pressureData) / 6)
					yAxis.push({
						type: 'value',
						name: '单位：' + unitValue,
						gridIndex: index,
						key: unitKey,
						alignTicks: true,
						// 可能会有负值
						// min: 0,
						// 不想数据=max,可手动更改interval*7 或 (interval*6)+ 20
						max: pressureInterval * 6 + pressureInterval * 2, // 最大值
						splitNumber: 6, // 坐标轴的分割段数(预估值)
						interval: pressureInterval, // 强制设置坐标轴分割间隔。
					})
				} else {
					let gasTemperatureData = that.currentDeviceObj?.status['gasTemperature']
						? that.currentDeviceObj?.status['gasTemperature'].map(item => {
								return item.value
						  })
						: []
					let gasTemperatureInterval = Math.ceil(Math.max(...gasTemperatureData) / 6)
					yAxis.push({
						type: 'value',
						name: '单位：' + unitValue,
						gridIndex: index,
						key: unitKey,
						alignTicks: true,
						// min: 0,
						// 不想数据=max,可手动更改interval*7 或 (interval*6)+ 20
						max: gasTemperatureInterval * 6 + gasTemperatureInterval * 2, // 最大值
						splitNumber: 6, // 坐标轴的分割段数(预估值)
						interval: gasTemperatureInterval, // 强制设置坐标轴分割间隔。
					})
				}
			})
		} else {
			yAxis.push({
				type: 'value',
				name: '单位：' + chartsConfig[key].unit,
				gridIndex: index,
				key,
			})
		}
	})

	let series = [] // 数据 series
	currentCharts.forEach((key, index) => {
		Object.keys(chartsConfig[key].legend).forEach(ckey => {
			// X轴数据（同一设备时间节点一致）
			if (that.currentDeviceObj?.status[ckey] && that.currentDeviceObj?.status[ckey].length > 0) {
				xData = that.currentDeviceObj?.status[ckey].map(item => {
					return item.time
				})
				let item = {
					name: chartsConfig[key].legend[ckey].split('\n').join(''),
					type: 'line',
					symbol: 'circle',
					symbolSize: 13,
					lineStyle: {
						width: 3,
					},
					// stack: "Total",
					data: that.currentDeviceObj?.status[ckey].map(item => {
						return item.value
					}),
					xAxisIndex: index,
				}
				if (key === 'pressure-gasTemperature') {
					item['yAxisIndex'] = yAxis.findIndex(y => y.key == ckey)
				} else {
					item['yAxisIndex'] = yAxis.findIndex(y => y.key == key)
				}
				series.push(item)
			}
		})
	})

	let option = {
		title: title,
		color: [
			'#3BBE40',
			'#FFCD1B',
			'#3BBE40',
			'#FFCD1B',
			'#3BBE40',
			'#FFCD1B',
			'#3BBE40',
			'#FFCD1B',
			'#3BBE40',
			'#FFCD1B',
		],
		grid: grid,
		tooltip: {
			trigger: 'axis',
		},
		legend,
		axisPointer: {
			link: { xAxisIndex: 'all' },
		},
		xAxis,
		yAxis,
		series,
		dataZoom: [
			{
				type: 'slider',
				show: true,
				bottom: '7px',
			},
		],
	}
	return option
}
export function channelInfomationChartsGas(that, chartsConfig, currentCharts) {
	// 图表高度(温度和压力在同一图中)
	// 计算当前展示的图
	// 标题
	let title = []
	// 布局分割
	let grid = []
	let legend = [] //legend
	let xAxis = [] // X轴/y轴
	let yAxis = []
	let xData = [] // X轴数据
	let series = [] // 数据 series
	let dataItem = []
	let sericeNameItem = ''
	let color = []
	let zoomIndex = []
	currentCharts.forEach((key, index) => {
		let legendItem = []
		that.currentDevices.forEach(deviceItem => {
			xData = xData.length
				? xData
				: deviceItem?.status[key].map(item => {
						return item.time
				  })
			sericeNameItem = key + '*' + deviceItem?.deviceNo
			legendItem.push({
				name: sericeNameItem || '',
				icon: 'roundRect',
			})
			dataItem = deviceItem?.status[key].map(item => {
				return item.value
			})
			series.push({
				name: sericeNameItem || '',
				type: 'line',
				data: dataItem,
				symbol: 'circle',
				symbolSize: 13,
				lineStyle: {
					width: 3,
				},
				xAxisIndex: index,
				yAxisIndex: index,
			})
		})
		grid.push({
			left: '3%',
			right: '4%',
			top:
				(100 / currentCharts.length) * index +
				10 -
				(currentCharts.length === 1 ? -8 : currentCharts.length) +
				'%',
			containLabel: true,
			height: '300px',
		})
		title.push({
			text: chartsConfig[key].title,
			textStyle: {
				color: '#000',
				fontWeight: 'bolder',
				fontSize: '18',
			},
			left: 'center',
			top: (100 / currentCharts.length) * index + '%',
		})
		legend.push({
			itemWidth: 12,
			itemHeight: 12,
			data: legendItem,
			x: 'center',
			top: (100 / currentCharts.length) * index + that.lengthMap[currentCharts.length] + '%',
			textStyle: {
				color: '#000',
				fontSize: 12,
			},
			formatter: function (name) {
				return name.split('*')[1]
			},
		})
		yAxis.push({
			type: 'value',
			name: '单位：' + chartsConfig[key].unit,
			gridIndex: index,
			key,
			// min: 0,
			alignTicks: true,
		})
		xAxis.push({
			gridIndex: index,
			type: 'category',
			scale: true,
			axisTick: {
				alignWithLabel: true,
			},
			data: xData, //x轴时间的数据
		})
		color = color.concat(that.color.slice(0, that.currentDevices.length))
		zoomIndex.push(index)
	})
	let option = {
		title: title,
		color: color,
		grid: grid,
		tooltip: {
			trigger: 'axis',
			formatter: function (dataList) {
				let tooltipBox = `<div class="chartTooltip">`
				let toopTipMap = {}
				dataList.forEach(item => {
					const { axisValue, seriesName, data, marker, axisIndex } = item
					if (!toopTipMap[axisIndex]) {
						toopTipMap[axisIndex] = []
					}
					toopTipMap[axisIndex].push({
						tooltipTitle: currentCharts[axisIndex] || '',
						axisValue,
						seriesName,
						data,
						marker,
					})
				})
				Object.keys(toopTipMap).forEach(tooltipItem => {
					let tooltipTitleText = ''
					let tooltipTitle = toopTipMap[tooltipItem][0]?.tooltipTitle
					if (tooltipTitle) {
						tooltipTitleText = chartsConfig[tooltipTitle].title
					}
					tooltipBox += `<div class="title">
                ${tooltipTitleText}:
                </div>
                <div class="title">
                ${toopTipMap[tooltipItem][0].axisValue}
                </div>
                `
					toopTipMap[tooltipItem].forEach(child => {
						const { marker, seriesName, data } = child
						tooltipBox += ` <div class="content">
                <span class="dotTooltip">
                ${marker}
                ${seriesName.split('*')[1]}:
              </span>
              <span>
                ${data}
              </span>
                </div>`
					})
				})
				//return回调一个模板字符串，自定义提示框的形状
				tooltipBox += `
             </div>
              `
				return tooltipBox
			},
		},
		legend,
		axisPointer: {
			link: { xAxisIndex: 'all' },
		},
		xAxis,
		yAxis,
		series,
		dataZoom: [
			{
				type: 'slider',
				show: true,
				bottom: '7px',
				xAxisIndex: zoomIndex,
			},
		],
	}
	return option
}
