import Layout from '@/layout'
import { deviceDetailPermissions } from '@/config/const'

export default [
	{
		order: 2,
		path: '/device',
		component: Layout,
		meta: {
			title: '设备管理',
			subtitle: '设备',
			icon: 'icon-shebei',
		},
		redirect: '/device/account',
		children: [
			{
				path: 'factory',
				name: 'factoryManage',
				meta: {
					title: '厂商管理',
					keepAlive: true,
					permissions: ['monitor:device:manufacturer:select', 'monitor:device:manufacturer:maintain'],
				},
				component: () => import('@/pages/device-manage/factory/index.vue'),
			},
			{
				path: 'type',
				name: 'typeManage',
				meta: {
					title: '类型管理',
					keepAlive: true,
					permissions: ['monitor:device:type:select', 'monitor:device:type:maintain'],
				},
				component: () => import('@/pages/device-manage/type/index.vue'),
			},
			{
				path: 'account',
				name: 'account',
				meta: {
					title: '台账管理',
					keepAlive: true,
					permissions: deviceDetailPermissions,
				},
				component: () => import('@/pages/device-manage/account/index.vue'),
			},
			// {
			//   path: "water-account",
			//   name: "waterAccount",
			//   meta: {
			//     title: "水务台账",
			//     keepAlive: true,
			//     permissions: deviceDetailPermissions,
			//   },
			//   component: () => import("@/pages/device-manage/account/water.vue"),
			// },
			{
				path: 'detail',
				name: 'deviceDetail',
				meta: {
					title: '设备详情',
					keepAlive: false,
					permissions: deviceDetailPermissions,
					hidden: true,
					paramsInTitle: 'deviceNo',
					customCrumb: [{ customCrumbTitle: '设备管理', path: '/device' }, { customCrumbTitle: '设备详情' }],
				},
				component: () => import('@/pages/device-manage/detail/index.vue'),
			},
			{
				path: 'blank',
				name: 'Blank',
				component: () => import('@/pages/device-manage/detail/Blank.vue'),
				meta: {
					permissions: deviceDetailPermissions,
					hidden: true,
				},
			},
			{
				path: 'batch-import',
				name: 'batchImport',
				meta: {
					title: '批量导入',
					keepAlive: true,
					permissions: ['monitor:device:account:maintain'],
				},
				component: () => import('@/pages/device-manage/batch-import/index.vue'),
			},
			{
				path: 'member',
				name: 'memberManage',
				meta: {
					title: '设备会员管理',
					keepAlive: true,
					permissions: ['monitor:device:member:query'],
				},
				component: () => import('@/pages/device-manage/member/index.vue'),
			},
		],
	},
]
