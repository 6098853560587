import request from '@/utils/request'
import { proxy as baseURL } from '@/config'

// 查询设备列表
export const apiGetDeviceList = data => {
	return request({
		url: '/monitor/ledger/device/list',
		baseURL,
		method: 'POST',
		data,
		pending: true,
	})
}

// 新增设备
export const apiAddDevice = data => {
	return request({
		url: '/monitor/ledger/device/add',
		method: 'POST',
		data,
		pending: true,
		needJointIsolation: true,
	})
}

// 安装设备
export const apiInstallDevice = data => {
	return request({
		url: '/monitor/ledger/device/install',
		method: 'POST',
		data,
		pending: true,
	})
}

//设备拆除
export const apiUninstallDevice = data => {
	return request({
		url: '/monitor/ledger/device/uninstall',
		method: 'POST',
		data,
		pending: true,
	})
}

//删除设备
export const apiDeleteDevice = deviceId => {
	return request({
		url: `/monitor/ledger/device/delete?deviceId=${deviceId}`,
		method: 'DELETE',
		pending: true,
	})
}

//修改设备信息
export const apiChangeDevice = data => {
	return request({
		url: '/monitor/ledger/device/update',
		method: 'POST',
		data,
		pending: true,
	})
}

// 设备告警列表
export const apiGetAlarmList = data => {
	return request({
		url: '/monitor/ledger/alarm/list',
		method: 'POST',
		data,
		pending: true,
		baseURL,
	})
}

// 设备告警总数
export const apiAlarmCount = deviceId => {
	return request({
		url: `/monitor/ledger/device/current-alarm/count?deviceId=${deviceId}`,
		method: 'GET',
		pending: true,
		baseURL,
	})
}

// 设备告警处理
export const apiAlarmAck = data => {
	return request({
		url: '/monitor/ledger/device/alarm/ack',
		method: 'POST',
		data,
		pending: true,
		baseURL,
	})
}

// 设备操作日志
export const apiGetOperateList = data => {
	return request({
		url: '/monitor/ledger/device/log/list',
		method: 'POST',
		data,
		pending: true,
		baseURL,
	})
}

// 地址列表查询
export const apiGetAdressList = data => {
	return request({
		url: '/monitor/ledger/address/list',
		method: 'POST',
		data,
		pending: true,
	})
}

// 获取已授予和已撤销的设备类型列表
export const apiGetDeviceTypeList = tenantId => {
	return request({
		url: `/monitor/deviceType/list?tenantId=${tenantId}`,
		method: 'GET',
		pending: true,
	})
}

// 租户获取已授予的设备类型列表
export const apiGetTenantDeviceTypeList = () => {
	return request({
		url: `/monitor/deviceType/tenant/device-type`,
		method: 'GET',
		pending: true,
	})
}

// 查看设备详情
export const apiGetDeviceDetail = deviceId => {
	return request({
		url: `/monitor/ledger/device/detail?deviceId=${deviceId}`,
		method: 'GET',
		pending: true,
		baseURL,
	})
}

// 编辑设备视图
export const apiEditDeviceView = params => {
	return request({
		url: `/monitor/ledger/device/edit/view`,
		method: 'GET',
		params,
		pending: false,
	})
}

// 获取网关编号列表
export const apiGetGatewayList = deviceNo => {
	return request({
		url: `/monitor/ledger/device/gateway/list?deviceNo=${deviceNo}`,
		method: 'GET',
		pending: false,
	})
}

// 获取一级省级行政区
export const apiGetProvinceList = () => {
	return request({
		url: `/monitor/region/province/list`,
		method: 'GET',
		pending: false,
		baseURL,
	})
}

// 获取二级省级行政区
export const apiGetCityList = code => {
	return request({
		url: `/monitor/region/city/list?code=${code}`,
		method: 'GET',
		pending: false,
		baseURL,
	})
}

// 获取三级省级行政区
export const apiGetDistrictList = code => {
	return request({
		url: `/monitor/region/district/list?code=${code}`,
		method: 'GET',
		pending: false,
	})
}

export const apiGetDeviceNoFromGas = params => {
	return request({
		url: `/v1/api/kitchen/gasDevice/getGasDeviceMap`,
		method: 'GET',
		params,
	})
}

// 通过小区名获取小区
export const apiGetAddressPoll = params => {
	return request({
		url: `/monitor/region/address-poll/list-by-areacode`,
		method: 'GET',
		params,
		pending: false,
	})
}

// 查询地址列表
export const apiGetAddressList = params => {
	return request({
		url: `/monitor/region/address/list-by-user`,
		method: 'GET',
		params,
		pending: false,
	})
}

// 指令记录
export const apiGetCmdmodelList = data => {
	return request({
		url: `/monitor/command/ledger/cmdmodel/list`,
		method: 'POST',
		data,
		pending: true,
		baseURL,
	})
}

export const apiGetRemoteSecurityCheckList = data => {
	return request({
		url: `/monitor/ledger/remoteSecurityCheck/list`,
		method: 'POST',
		data,
		pending: true,
		baseURL,
	})
}

// 采集记录
export const apiGetCollectList = data => {
	return request({
		url: `/monitor/ledger/collect/list`,
		method: 'POST',
		data,
		pending: true,
	})
}

// 设备状态监控
export const apiGetDeviceStatus = deviceId => {
	return request({
		url: `/monitor/ledger/device/status?deviceId=${deviceId}`,
		method: 'GET',
		pending: true,
		baseURL,
	})
}

// 设备通讯记录
export const apiGetCommunicationList = data => {
	return request({
		url: `/monitor/ledger/communication/list`,
		method: 'POST',
		pending: true,
		data,
		baseURL,
	})
}

// 设备通讯记录
export const apiGetAbilityCmdmodelList = () => {
	return request({
		url: `/monitor/command/ledger/ability/cmdmodel/list`,
		method: 'GET',
		pending: true,
		baseURL,
	})
}

// 历史数据展示
export const apiGetHistoryStatus = data => {
	return request({
		url: `/monitor/ledger/device/history/status`,
		method: 'POST',
		pending: true,
		data,
		baseURL,
	})
}
// 历史数据展示-存在多种气体
export const apiGetHistoryStatusVaried = data => {
	return request({
		url: `/monitor/ledger/device/history/alarmStatus`,
		method: 'POST',
		pending: true,
		data,
		baseURL,
	})
}

// 批量导入
export const apiBatchImport = data => {
	return request({
		url: '/monitor/ledger/device/batch-save',
		method: 'POST',
		data,
		pending: true,
		needJointIsolation: true,
		timeout: 180000,
	})
}

export const apiStaffsCity = (params = {}) => {
	return request({
		// url: "/v1/utos/staffs/city",
		url: '/monitor/ledger/getProvinceAndCity',
		method: 'GET',
		pending: true,
		baseURL,
		needJointIsolation: true,
		timeout: 180000,
		params,
	})
}

// 关联设备列表
export const apiGetRelationDeviceList = params => {
	return request({
		url: '/monitor/gateway-device/sub-device/linked/list',
		method: 'GET',
		pending: true,
		needJointIsolation: true,
		params,
	})
}

// 获取已安装子设备详情
export const apiGetSubDeviceDetail = params => {
	return request({
		url: '/monitor/gateway-device/sub-device/pre-link/detail',
		method: 'GET',
		pending: true,
		needJointIsolation: true,
		params,
	})
}

// 新增关联设备
export const apiAddRelationDevice = data => {
	return request({
		url: '/monitor/gateway-device/sub-device/add',
		method: 'POST',
		pending: true,
		needJointIsolation: true,
		data,
	})
}

// 解除关联设备
export const apiDeleteRelationDevice = data => {
	return request({
		url: '/monitor/gateway-device/sub-device/delete',
		method: 'DELETE',
		pending: true,
		needJointIsolation: true,
		data,
	})
}

// 获取关联设备时点数据
export const apiGetTimePointData = data => {
	return request({
		url: '/monitor/gateway-device/category/history/status',
		method: 'POST',
		pending: true,
		needJointIsolation: true,
		data,
	})
}

// 设备规则配置列表查询
export const apiGetDeviceRuleConfig = params => {
	return request({
		url: '/monitor/ledger/device/function-set-info',
		method: 'GET',
		pending: true,
		needJointIsolation: true,
		params,
	})
}

// VEE规则配置列表查询
export const apiGetVeeRuleConfig = params => {
	return request({
		url: '/monitor/ledger/device/event-model-config',
		method: 'GET',
		pending: true,
		needJointIsolation: true,
		params,
	})
}

// VEE规则修改
export const apiSetVeeRuleConfig = data => {
	return request({
		url: '/monitor/ledger/device/event-model-config-set',
		method: 'POST',
		pending: true,
		needJointIsolation: true,
		data,
	})
}

// 一键同步C端用户信息
export const apiUaSync = params => {
	return request({
		url: '/monitor/ledger/ua/sync',
		method: 'POST',
		pending: true,
		params,
		timeout: 180000,
	})
}

// 一键同步C端用户信息
export const apiReSend = params => {
	return request({
		url: '/monitor/command/ledger/cmdmodel/singleReSend',
		method: 'GET',
		pending: true,
		params,
	})
}

export const apiBatchEdit = (data = {}) =>
	request({
		method: 'post',
		data,
		pending: true,
		url: '/monitor/ledger/device/batch-update',
	})
