import Layout from '@/layout'

export default [
	{
		order: 3,
		path: '/firmware',
		component: Layout,
		meta: {
			title: '固件升级',
			subtitle: '固件',
			icon: 'icon-juminbiao',
		},
		redirect: '/firmware/manage',
		children: [
			{
				path: 'device-manage',
				name: 'deviceManage',
				meta: {
					title: '设备管理',
					keepAlive: true,
					permissions: ['ota:ota:device'],
				},
				component: () => import('@/pages/firmware-upgrade/device-manage/index.vue'),
			},
			{
				path: 'device-manage/details',
				name: 'deviceManageDetails',
				meta: {
					title: '设备详情',
					keepAlive: false,
					hidden: true,
					permissions: ['ota:ota:device'],
				},
				component: () => import('@/pages/firmware-upgrade/device-manage/Details.vue'),
			},
			{
				path: 'firmware-manage',
				name: 'firmwareManage',
				meta: {
					title: '固件管理',
					keepAlive: true,
					permissions: ['ota:ota:firmware'],
				},
				component: () => import('@/pages/firmware-upgrade/firmware-manage/index.vue'),
			},
			{
				path: 'upgrade-task',
				name: 'upgradeTask',
				meta: {
					title: '升级任务',
					keepAlive: true,
					permissions: ['ota:ota:task'],
				},
				component: () => import('@/pages/firmware-upgrade/upgrade-task/index.vue'),
			},
			{
				path: 'upgrade-task/details',
				name: 'upgradeTaskDetails',
				meta: {
					title: '任务详情',
					keepAlive: false,
					hidden: true,
					permissions: ['ota:ota:task'],
					customCrumb: [
						{
							customCrumbTitle: '固件升级',
							path: '/firmware',
						},
						{
							customCrumbTitle: '升级任务',
							customCrumbPath: '/firmware/upgrade-task',
						},
						{
							customCrumbTitle: '任务详情',
						},
					],
				},
				component: () => import('@/pages/firmware-upgrade/upgrade-task/Details.vue'),
			},
		],
	},
]
