<template>
	<div class="layout">
		<template>
			<layout-sidebar class="layout-left" :style="{ width: sidebarWidth }" />
			<div class="layout-right">
				<div class="layout-top">
					<layout-header class="layout-header" />
				</div>
				<TagsView />
				<layout-main class="layout-main" />
			</div>
		</template>

		<switch-org-dialog :show.sync="showSwitchOrgDialog" :org-list="orgList" />

		<!-- 切换租户 -->
		<SwitchTenantDialog v-if="switchTenantModalVisible" @close-modal="closeSwitchTenantModal" />
	</div>
</template>

<script>
import LayoutSidebar from './components/sidebar/index'
import LayoutHeader from './components/header/index'
import LayoutMain from './components/Main'
import SwitchOrgDialog from './components/SwitchOrgDialog'
import SwitchTenantDialog from './components/SwitchTenantDialog'
import TagsView from './components/tags-view/index'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { apiTenantIsolationOrg } from '@/apis/login.api'

export default {
	name: 'Layout',
	components: {
		LayoutSidebar,
		LayoutHeader,
		LayoutMain,
		SwitchOrgDialog,
		SwitchTenantDialog,
		TagsView,
	},
	data() {
		return {
			orgList: [],
		}
	},
	computed: {
		...mapGetters({
			fold: 'app/fold',
			userInfo: 'userInfo',
			isolationOrgId: 'isolationOrgId',
			isolationOrgCode: 'isolationOrgCode',
			showSwitchOrgDialogGlobal: 'showSwitchOrgDialogGlobal',
		}),
		...mapState({
			switchTenantModalVisible: state => state.user.switchTenantModalVisible,
		}),
		// 左侧导航宽度
		sidebarWidth() {
			return this.fold ? '80px' : '240px'
		},
		showSwitchOrgDialog: {
			get() {
				return this.showSwitchOrgDialogGlobal
			},
			set(val) {
				this.changeShowSwitchOrgDialogGlobal(val)
			},
		},
		peekIsolation() {
			// const { isolationOrgId, isolationOrgCode, userInfo } =
			//   this.$store.getters;
			return this.userInfo?.needPeekIsolation && (!this.isolationOrgId || !this.isolationOrgCode)
		},
	},
	mounted() {
		if (this.userInfo?.needPeekIsolation) {
			this.getOrgList()
		}
	},
	methods: {
		...mapMutations({
			changeShowSwitchOrgDialogGlobal: 'user/CHANGE_SHOW_SWITCH_ORG_DIALOG_GLOBAL',
			updateSwitchTenantModalVisible: 'user/UPDATE_SWITCH_TENANT_MODAL_VISIBLE',
		}),
		getOrgList() {
			apiTenantIsolationOrg().then(({ dataList = [] }) => {
				this.showLayout = true
				if (!dataList.length) return
				this.orgList = dataList
			})
		},

		closeSwitchTenantModal() {
			this.updateSwitchTenantModalVisible(false)
		},
	},
}
</script>

<style lang="scss" scoped>
.layout {
	width: 100vw;
	height: 100vh;
	display: flex;

	&-left {
		transition: width 0.3s;
		z-index: 1; // 凸显右侧阴影
	}

	&-right {
		flex: 1;
		width: 0;
		.layout-top {
			height: 60px;
			background: #fff;
			position: relative;
			border-bottom: 1px solid #f1f1f1;
			.layout-header {
				height: 60px;
			}
		}

		.layout-main {
			height: calc(100vh - 94px);
			background: #fff;
			overflow: hidden;
		}
	}
}
</style>
