// 全局配置状态管理
import { getLS, setLS, removeLS } from '@/utils/storage'
import { isBlank } from '@/utils/validate'
import { layout, themeName, title, isFold } from '@/config'

const defaultTheme = {
	layout,
	themeName,
}
const collapse = getLS('collapse')

const state = () => ({
	title,
	device: 'desktop',
	collapse: isBlank(collapse) ? isFold : collapse,
	theme: getLS('theme') || defaultTheme,
	extra: { first: '', transferRouteName: '' },
	showToast: false,
	showHoverMenu: true, // 是否显示悬浮子菜单，点击子菜单路由跳转后悬浮菜单消失
})
const getters = {
	title: state => state.title,
	device: state => state.device,
	collapse: state => state.collapse,
	theme: state => state.theme,
	extra: state => state.extra,
	showHoverMenu: state => state.showHoverMenu,
}
const mutations = {
	OPEN_SIDEBAR(state) {
		state.collapse = false
	},
	FOLD_SIDEBAR(state) {
		state.collapse = true
	},
	TOGGLE_DEVICE(state, device) {
		state.device = device
	},
	TOGGLE_COLLAPSE(state) {
		state.collapse = !state.collapse
		setLS('collapse', state.collapse)
	},
	SAVE_THEME(state) {
		setLS('theme', JSON.stringify(state.theme))
	},
	RESET_THEME(state) {
		state.theme = { ...defaultTheme }
		removeLS('theme')
		document.getElementsByTagName('body')[0].className = `gc-theme-${state.theme.themeName}`
	},
	SET_SHOW_TOAST(state, flag) {
		state.showToast = flag
	},
	SET_EXTRA(state, extra) {
		state.extra = extra
	},
	SET_SHOW_HOVER_MENU(state, flag) {
		if (state.showHoverMenu === flag) return
		state.showHoverMenu = flag || false
	},
}
const actions = {
	openSideBar({ commit }) {
		commit('OPEN_SIDEBAR')
	},
	foldSideBar({ commit }) {
		commit('FOLD_SIDEBAR')
	},
	toggleDevice({ commit }, device) {
		commit('TOGGLE_DEVICE', device)
	},
	toggleCollapse({ commit }) {
		commit('TOGGLE_COLLAPSE')
	},
	saveTheme({ commit }) {
		commit('SAVE_THEME')
	},
	resetTheme({ commit }) {
		commit('RESET_THEME')
	},
	setSubmenu({ commit }, flag) {
		commit('SET_SHOW_HOVER_MENU', flag)
	},
}
export default { state, getters, mutations, actions }
