<template>
	<el-popover placement="bottom" width="300" trigger="hover" popper-class="popover-sound-setting">
		<div class="container">
			<div class="title">消息中心播放设置</div>
			<el-form ref="formRef" :rules="rules" :model="form" class="box-form">
				<el-form-item prop="soundDuration">
					<div class="content">
						声音播放时长:
						<el-input
							class="sound-input"
							v-model="form.soundDuration"
							placeholder="请输入时长"
							@keyup.enter.native.stop="handleEnter"
							@blur="handleBlur"
						/>
						分钟
						<el-tooltip effect="dark" placement="top">
							<div slot="content">
								限一位小数，比如 0.1 * 60 = 6秒
								<br />
								最小可设置为0，表示不播报声音
								<br />
								最大可设置为525600，表示播放时长为1年
							</div>
							<i class="el-icon-info"></i>
						</el-tooltip>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div slot="reference" v-if="hasAlarmRecordsAuth" class="box-alarm" @click="handleJump">
			<i class="iconfont icon-lingdang" />
			<span v-show="alarmCount" class="alarm-count">{{ alarmCount }}</span>
		</div>
	</el-popover>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ruleRequired, RULE_SOUND_DURATION } from '@/utils/rules'
import { apiSetSoundDuration } from '@/apis/tenant.api.js'

export default {
	name: 'AlarmSetting',
	data() {
		return {
			form: {
				soundDuration: 10,
			},
			rules: {
				soundDuration: [ruleRequired('请输入声音播放时长'), RULE_SOUND_DURATION],
			},
		}
	},
	computed: {
		...mapGetters({
			alarmCount: 'alarmPush/alarmCount',
			soundDuration: 'alarmPush/soundDuration',
		}),
		hasAlarmRecordsAuth() {
			return this.$has('monitor:run:alarm-record:select')
		},
		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
	},
	watch: {
		'$store.state.alarmPush.soundDuration'(val) {
			this.form.soundDuration = val
		},
	},
	methods: {
		...mapMutations({
			setAlarmCount: 'alarmPush/SET_ALARM_COUNT',
		}),
		...mapActions({
			getSoundDuration: 'alarmPush/getSoundDuration',
			mockAlarmPush: 'alarmPush/mockAlarmPush',
		}),
		handleJump() {
			if (this.alarmCount) {
				this.setAlarmCount(0)
				this.$router.push({
					name: 'alarmRecords',
					params: {
						update: true,
					},
				})
			}
		},
		handleEnter(event) {
			let flag = false
			this.$refs.formRef.validate(valid => {
				flag = valid
			})
			if (!flag) return
			event.target.blur()
		},
		handleBlur() {
			let flag = false
			this.$refs.formRef.validate(valid => {
				flag = valid
			})
			if (!flag) {
				this.form.soundDuration = this.soundDuration
				return
			}
			this.setSoundDuration()
		},
		setSoundDuration() {
			// this.mockAlarmPush();
			// 本次设置时长和系统记录时长相同时，不调用接口
			if (this.form.soundDuration == this.soundDuration) return
			const params = {
				configs: [{ key: 'alarm_sound_duration', value: this.form.soundDuration }],
				tenantId: this.tenantId,
			}
			apiSetSoundDuration(params).then(() => {
				this.$message.success('设置播放时长成功！')
				// 更新声音播放时长
				this.getSoundDuration()
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.box-alarm {
	margin-left: 26px;
	position: relative;
	.iconfont {
		font-size: 32px;
		color: #c1c6db;
		cursor: pointer;
	}
	.alarm-count {
		position: absolute;
		top: 0px;
		left: 50%;
		height: 16px;
		min-width: 16px;
		padding: 0 4px;
		border-radius: 8px;
		background: #e5662e;
		font-size: 12px;
		color: #fff;
		@include flex-center;
	}
}
</style>

<style lang="scss">
.el-popper.popover-sound-setting {
	padding: 0;
	border-radius: 5px;
	overflow: hidden;

	.popper__arrow {
		display: none;
	}

	.title {
		height: 40px;
		line-height: 40px;
		background-color: $base-color-theme;
		color: #fff;
		padding-left: 20px;
	}

	.box-form {
		padding: 20px;
	}

	.content {
		display: flex;
		align-items: center;

		.sound-input {
			margin-left: 20px;
			margin-right: 10px;
			flex: 1;
		}
	}
}
</style>
