// 修改配置项时需重启项目

const title = 'IoT监控平台' // 标题

// 高德地图Key、安全密钥、自定义地图样式ID
const AMapKey = '95b533dc58b44f3cbae93cd9efff0858'
const AMapSecret = '6171dc6a7993ecde4079b2646d36f5bb'
const AMapStyleid = '397b948b44d8be326614546c8353fd27'

const isProd = process.env.NODE_ENV === 'production' // 是否为生产环境
const proxy = isProd ? '/' : '/api' // 代理映射

const manageProxy = isProd ? '/' : '/manageApi' // manage
const zbTestProxy = isProd ? '/' : '/zbTest'
const skProxy = isProd ? '/' : '/skwTest'
const etbcAuthProxy = isProd ? '/' : '/etbcAuthProxy'

const proxyList = [
	{
		proxy: etbcAuthProxy,
		target: 'http://iot.test.eslink.net.cn',
	},
	{
		proxy: zbTestProxy,
		target: 'http://zb-test.iot.cn',
	},
	{
		proxy: skProxy,
		target: 'http://10.20.11.206:28111',
	},
	{
		proxy: proxy,
		// target: 'http://iot.test.eslink.net.cn',
		target: 'http://shwcs.iot.cn', // 水测试环境，zb,zb1admin/jk300349，zhaobo1/jk300349
		// target: "http://shenran.iot.cn",
		// target: "http://water0820.iot-water.cn", // shwcsadmin/jk300349
		// target: 'https://test0620.iot-test.jinka.cn', //pzztest  jk300349
		// target: "http://test1129.iot.cn",
		// target: 'http://test0331.iot.cn',
		// target: "http://lkd02.iot-water.cn", // lkd jk300349
		// target: 'http://test1216.iot.cn', //cytest2/000000a
		// target: 'http://manage.iot.cn', //jkadmin/000000a
	},
	{
		proxy: manageProxy,
		target: 'http://manage.iot.cn',
		// target: "http://manage.iot-sr.cn",
	},
	{
		proxy: '/pzzTest',
		target: 'http://pzztest.iot.cn',
	},
]

module.exports = {
	title,
	AMapKey,
	AMapSecret,
	AMapStyleid,
	isProd,
	proxy,
	manageProxy,
	baseURL: isProd ? '/' : proxy, // 接口默认地址
	proxyList: isProd ? [] : proxyList,
	zbTestProxy,
	skProxy,
	etbcAuthProxy,
}
