<template>
	<div class="group-detail-item" :style="{ 'flex-direction': direction }">
		<div
			class="group-detail-name"
			:style="{
				width: labelWidth ? labelWidth + 'px' : undefined,
			}"
		>
			<p>{{ name + (direction === 'row' ? '：' : '') }}</p>
		</div>
		<div class="group-detail-info">
			<div><slot></slot></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GroupDetailItem',
	componentName: 'GroupDetailItem',
	props: {
		name: String,
		isDouble: Boolean,
		isEllipsis: Boolean,
		labelWidth: [Number, String],
		direction: {
			type: String,
			default: 'row',
		},
	},

	data() {
		return {
			clientWidth: 0,
		}
	},
}
</script>

<style lang="scss" scoped>
.group-detail-item {
	width: 25%;
	margin-top: 20px;
	flex-shrink: 0;
	box-sizing: border-box;
	display: flex;
	padding-right: 20px;
	.group-detail-name,
	.group-detail-info {
		line-height: 21px;
	}
	.group-detail-name {
		color: #999;
		flex-shrink: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.group-detail-info {
		flex: 1;
		width: 0;
		div {
			color: #333;
			p {
				word-break: break-all;
			}
		}
	}
}
</style>
