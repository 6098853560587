import getReqUtil from '@/utils/reqUtils'
import { proxy } from '@/config'
const { reqDELETE, reqGET, reqPOST, reqPUT } = getReqUtil(proxy)

// 获取角色列表
export const apiGetRoleList = data => reqGET(data, '/v1/utos/roles')

// 获取角色列表
export const apiGetStaffRoleList = () => reqGET({}, '/monitor/tos/staff/role/list')

export const apiGetOrganizeList = () => reqGET({}, '/monitor/tos/org/list')

// 新建角色
export const apiAddRole = data => reqPOST(data, '/v1/utos/roles', false, false)

// 获取租户使用的权限模板
export const apiGetTenantTemplate = data => reqGET(data, '/auth/authz/permission/template/scope/ref')

// 获取角色内权限树
export const apiGetPermissionOfRole = data => reqPOST(data, '/auth/authz/permission/group/tenant/role/tree', true)

//修改角色
export const apiModifyRole = data => reqPUT(data, '/v1/utos/roles')

//删除角色
export const apiDeleteRole = data => reqDELETE(data, `/v1/utos/roles/${data}`)

// 获取租户功能树
export const apiGetTenantFunctionTree = data => reqGET(data, '/auth/authz/functional/tree/tenant')
// 获取租户角色已开通功能
export const apiGetRoleFunction = data => reqGET(data, '/auth/authz/functional/granted/role')
