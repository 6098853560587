import { apiStaffList } from '@/apis/organizeStaff.api'
import { apiGetProvinceList } from '@/apis/account.api'
import { apiGetTenantTemplate } from '@/apis/organizeRole.api'
import { apiGetWaterRegionTree } from '@/apis/regionManage.api'

export default {
	state: {
		staffList: null, // 告警消息策略员工列表
		provinceList: null, // 一级省级行政区列表
		srcTemplateId: '', // 租户使用的权限模板id
		treeOptions: [], //区域树
	},
	mutations: {
		SET_STAFF_LIST(state, payload) {
			state.staffList = payload
		},
		SET_PROVINCE_LIST(state, payload) {
			state.provinceList = payload
		},
		SET_SRC_TEMPLATE_ID(state, id) {
			state.srcTemplateId = id
		},
		SET_TREE_OPTIONS(state, payload) {
			state.treeOptions = payload
		},
	},
	actions: {
		getTenantSrcTemplateId({ state, commit }, data) {
			return new Promise((resolve, reject) => {
				if (state.srcTemplateId) {
					resolve(state.srcTemplateId)
				} else {
					apiGetTenantTemplate(data)
						.then(res => {
							if (res) {
								commit('SET_SRC_TEMPLATE_ID', res.templateId)
								resolve(res.templateId)
							} else {
								reject(new Error('该暂未应用权限模板，请联系管理员'))
							}
						})
						.catch(err => reject(err))
				}
			})
		},
		getStaffList({ commit }, data = {}) {
			return new Promise((resolve, reject) => {
				apiStaffList({
					current: 1,
					size: 1000,
					...data,
				})
					.then(({ records = [] }) => {
						commit('SET_STAFF_LIST', records)
						resolve(records)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		getProvinceList({ state, commit }) {
			return new Promise((resolve, reject) => {
				if (state.provinceList) {
					resolve(state.provinceList)
				} else {
					apiGetProvinceList()
						.then(({ records = [] }) => {
							commit('SET_PROVINCE_LIST', records)
							resolve(records)
						})
						.catch(err => {
							reject(err)
						})
				}
			})
		},
		getTreeOptions({ state, commit, rootGetters }, updateFlag = false) {
			return new Promise((resolve, reject) => {
				// 燃气租户不请求水务租户的区域树
				if (rootGetters.userInfo.tenantType !== '1') {
					return resolve([])
				}
				if (state.treeOptions.length > 0 && !updateFlag) {
					resolve(state.treeOptions)
				} else {
					apiGetWaterRegionTree()
						.then(data => {
							commit('SET_TREE_OPTIONS', data)
							resolve(data)
						})
						.catch(err => {
							reject(err)
						})
				}
			})
		},
	},
}
