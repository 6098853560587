<template>
	<gc-dialog
		title="处理登记"
		:show.sync="visible"
		@ok="handleOk"
		@cancel="visible = false"
		@close="visible = false"
		:append-to-body="true"
	>
		<el-form ref="form" :model="form" :rules="formRules" label-position="top">
			<el-form-item label="处理方式" prop="processType">
				<el-radio-group v-model="form.processType">
					<el-radio :label="1">电话处理</el-radio>
					<el-radio :label="2">现场处理</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="alarmId" label="处理人" prop="processUser">
				<el-input type="text" v-model="form.processUser" placeholder="请输入处理人" />
			</el-form-item>
			<el-form-item label="处理时间" prop="processDate">
				<el-date-picker
					v-model="form.processDate"
					type="datetime"
					value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="请选择处理时间"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="处理描述" prop="processDesc">
				<el-input type="text" v-model="form.processDesc" placeholder="请填写处理描述" />
			</el-form-item>
			<el-form-item label="已正常">
				<el-radio-group v-model="form.processResult">
					<el-radio :label="1">是</el-radio>
					<el-radio :label="0">否</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>
	</gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength } from '@/utils/rules'
import { apiExceptionLog } from '@/apis/alarm.api'
import { apiAlarmAck } from '@/apis/account.api'

export default {
	name: 'GcAlarmHandleDialog',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		detail: {
			type: Object,
			default: () => {},
		},
		alarmId: [Number, String],
	},
	data() {
		return {
			form: {
				processType: '',
				processDate: '',
				processUser: '',
				processDesc: '',
				processResult: '',
			},
			formRules: {
				processType: [ruleRequired('请选择处理方式')],
				processDesc: [ruleMaxLength(200)],
			},
		}
	},
	computed: {
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
	},
	watch: {
		show(val) {
			if (!val) {
				this.form = this.$options.data().form
				this.$refs.form?.resetFields()
			}
		},
	},
	methods: {
		handleOk() {
			let flag = false
			this.$refs.form.validate(valid => {
				flag = valid
			})
			if (!flag) return
			const params = {
				deviceId: this.alarmId ? undefined : this.detail?.deviceId,
				alarmId: this.alarmId,
				...this.form,
			}
			const API = this.alarmId ? apiAlarmAck : apiExceptionLog
			API(params)
				.then(() => {
					this.$message.success('处理登记成功！')
					this.visible = false
					this.$emit('ops-success')
				})
				.catch(err => {
					console.error(err)
				})
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.el-input__suffix-inner .iconfont {
		font-size: 22px;
		cursor: pointer;
	}
}
</style>
