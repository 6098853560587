import Vue from 'vue'

import '@/styles/index.scss'
// import "@/assets/iconfont/iconfont.css";
// import "@/assets/iconfont/iconfont.js";

import components from '@/components/index'
Vue.use(components)

//全局指令
import directives from '@/directives/index.js'
Vue.use(directives)

// 批量导入模块
const all = require.context('@/global', false, /\.js$/)
all.keys().map(all)
