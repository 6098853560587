<template>
	<div class="app-main">
		<keep-alive>
			<router-view v-if="keepAlive"></router-view>
		</keep-alive>
		<router-view :key="$route.fullPath" v-if="!keepAlive"></router-view>
	</div>
</template>

<script>
export default {
	name: 'LayoutMain',
	computed: {
		keepAlive() {
			return this.$route.meta?.keepAlive
		},
	},
}
</script>

<style scoped>
.app-main {
	overflow: auto;
}
</style>
