// 框架层工具方法

import { resolve } from 'path'
import store from '@/store'
import { isBlank } from './validate'

// 获取路由中的固定标签页
export const getAffixTags = routes => {
	return routes
		.reduce((total, current) => [...total, ...(current?.children || [])], [])
		.filter(route => route?.meta?.affix)
}

/**
 * 判断当前路由是否有权限
 * @param {Array} permissions 用户权限列表
 * @param {Array} route 当前路由
 * @returns {Boolean}
 */
export function hasPermission(permissions, route) {
	const array = route.meta?.permissions || []
	if ((store.getters.userInfo?.userLevel === 0 || store.getters.userInfo?.isTenantAdmin) && route.meta?.sysHide) {
		return false // 系统管理员没有台账管理和告警记录的菜单权限
	}
	if (!array.length) return true // 当前路由meta没有设置permissions参数时默认有权限
	return permissions.some(permission => array.includes(permission))
}

/**
 * 递归过滤异步路由
 * @param {Array} routes 路由列表
 * @param {Array} permissions 用户权限列表
 * @param {String} basePath 上级路由的fullPath
 * @returns {Array}
 */
export function filterAsyncRoutes(routes, permissions, basePath = '/', tenantType) {
	const res = []
	routes.forEach(route => {
		const tmp = { ...route }
		if (
			(!tmp?.meta?.hidden || tmp?.meta?.hiddenButCanEnter) &&
			hasPermission(permissions, tmp) &&
			(isBlank(tmp?.meta?.tenantType) || String(tmp.meta.tenantType) === tenantType)
		) {
			tmp.fullPath = resolve(basePath, tmp.path) // 路由地址补全
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(tmp.children, permissions, tmp.fullPath, tenantType)
			}
			res.push(tmp)
		}
	})
	return res.filter(item => !item.children || item.children.length > 0)
}
