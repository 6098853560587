import getReqUtil from '@/utils/reqUtils'
import { proxy } from '@/config'
const { reqDELETE, reqGET, reqPOST, reqPUT } = getReqUtil(proxy)
// 修改员工账号
export const apiModifyStaffAccount = data => reqPUT(data, '/v1/utos/staffs/accounts')

// 修改员工密码
export const apiModifyStaffPwd = data => reqPOST(data, '/auth/aggregation/password/tenant/manage/update', true)

// 获取组织树
export const apiGetOrganizations = data => reqGET(data, '/v1/utos/organizations')

// 查询组织结构
export const apiGetOrgStruct = data => reqGET(data, '/v1/utos/organizations/struct')

// 获取上级部门
export const apiGetParentDepartment = data => reqGET(data, '/v1/utos/organizations/parent')

// 新建、修改、删除组织
export const apiAddOrg = data => reqPOST(data, '/v1/utos/organizations', false, false)
export const apiModifyOrg = data => reqPUT(data, '/v1/utos/organizations')
export const apiDeleteOrg = data => reqDELETE(data, `/v1/utos/organizations/${data}`)

// 获取员工列表
export const apiGetStaffList = params => reqGET(params, '/v1/utos/staffs')

// 获取员工详情
export const apiGetStaffDetail = data => reqGET({}, `/v1/utos/staffs/${data}`)

// 查询员工
export const apiStaffList = data =>
	reqPOST(data, '/monitor/tos/staff/list', false, true, {
		needJointIsolation: true,
	})

//   新增\修改员工
export const apiAddStaff = data => reqPOST(data, '/v1/utos/staffs', false, false)
export const apiModifyStaff = data => reqPUT(data, '/v1/utos/staffs')

// 生成授权链接
export const apiQrcodeUrl = params => reqGET(params, '/monitor/wx/connect')
