import Layout from '@/layout'

export default [
	{
		order: 6,
		path: '/statistics',
		component: Layout,
		meta: {
			title: '统计分析',
			subtitle: '统计',
			icon: 'icon-tongji',
			hiddenCrumb: true,
		},
		redirect: '/statistics/deviceDevelop',
		children: [
			{
				path: 'deviceDevelop',
				name: 'deviceDevelop',
				meta: {
					title: '设备发展量统计',
					keepAlive: true,
					permissions: ['monitor:statistical:device-development'],
				},
				component: () => import('@/pages/statistic-analysis/pages/DeviceDevelop.vue'),
			},
			{
				path: 'alarmDeal',
				name: 'alarmDeal',
				meta: {
					title: '告警处理情况统计',
					keepAlive: true,
					permissions: ['monitor:statistical:alarm-handling'],
				},
				component: () => import('@/pages/statistic-analysis/pages/AlarmDeal.vue'),
			},
			{
				path: 'factoryAlarm',
				name: 'factoryAlarm',
				meta: {
					title: '厂商告警设备对比',
					keepAlive: true,
					permissions: ['monitor:statistical:manufacturer-alarm'],
				},
				component: () => import('@/pages/statistic-analysis/pages/FactoryAlarm.vue'),
			},
			{
				path: 'gas-statistics',
				name: 'GasStatisticsReport',
				meta: {
					title: '用气统计报表',
					keepAlive: true,
					permissions: ['monitor:statistical:gas-report'],
				},
				component: () => import('@/pages/statistic-analysis/pages/GasStatistics.vue'),
			},
			{
				path: 'meter-rate',
				name: 'meterRate',
				meta: {
					title: '抄表率统计',
					keepAlive: true,
					permissions: ['monitor:report:meter-reading'],
				},
				component: () => import('@/pages/statistic-analysis/pages/MeterRate.vue'),
			},
			{
				path: 'no-upload',
				name: 'manyDaysNoUpload',
				meta: {
					title: '多天不上传统计',
					keepAlive: true,
					permissions: ['monitor:report:exception:no-upload'],
				},
				component: () => import('@/pages/statistic-analysis/pages/ManyDaysNoUpload.vue'),
			},
			{
				path: 'valve-operate',
				name: 'valveOperate',
				meta: {
					title: '阀门操作统计',
					keepAlive: true,
					permissions: ['monitor:report:exception:valve-opt'],
				},
				component: () => import('@/pages/statistic-analysis/pages/ValveOperate.vue'),
			},
			{
				path: 'large-water-analysis',
				name: 'largeWaterAnalysis',
				meta: {
					title: '大表水量分析',
					keepAlive: true,
					permissions: ['monitor:water:largescale:preview'],
				},
				component: () => import('@/pages/statistic-analysis/pages/largeWaterAnalysis.vue'),
			},
			{
				path: 'small-water-analysis',
				name: 'smallWaterAnalysis',
				meta: {
					title: '小表水量分析',
					keepAlive: true,
					permissions: ['monitor:report:water:smallscale:preview'],
				},
				component: () => import('@/pages/statistic-analysis/pages/SmallWaterAnalysis.vue'),
			},
		],
	},
]
