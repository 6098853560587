import request from '@/utils/request'
import { isProd, etbcAuthProxy } from '@/config'

//获取验证码
export const apiCodeImg = params =>
	request({
		url: '/auth/authn/code',
		method: 'post',
		params,
	})

// 登录
export const apiLogin = (data, headers) =>
	request({
		url: '/auth/aggregation/utos/authn',
		method: 'post',
		data,
		headers,
	})

// ETBC登录
export const apiEtbcLogin = () =>
	request({
		url: '/auth/aggregation/etbc/authn',
		baseURL: isProd ? '/' : etbcAuthProxy,
		method: 'post',
	})

// 查询所有前后端枚举交互翻译列表
export const apiDataList = params => {
	return request({
		url: '/monitor/dict/list',
		method: 'get',
		pending: true,
		params,
	})
}

// 租户账户修改自身密码
export const apiModifyTenantPwd = (params, others) => {
	return request({
		url: 'auth/aggregation/password/tenant/update',
		method: 'post',
		params,
		...others,
	})
}
// 系统账户修改自身密码
export const apiModifyAdminPwd = (params, others) => {
	return request({
		url: 'auth/aggregation/password/sys/update',
		method: 'post',
		params,
		...others,
	})
}

// 切换租户
export const apiSwitchTenant = data => {
	return request({
		url: '/auth/aggregation/ops/switch',
		method: 'post',
		data,
	})
}

// 查询运维人员当前选中的租户
export const apiGetcurrentTenant = () => {
	return request({
		url: '/auth/aggregation/ops/query',
		method: 'get',
	})
}

// 查询租户下数据隔离级别组织
export const apiTenantIsolationOrg = () => {
	return request({
		url: '/v1/utos/organizations/isolation',
		method: 'get',
		pending: true,
	})
}

export const apiGetTenantDetails = tenantId => {
	return request({
		url: `/monitor/v1/utos/tenants/${tenantId}`,
		method: 'get',
	})
}
