/**
 * 解决点击el-button后样式不还原问题
 * 通过点击按钮后失焦实现
 * 用法：<el-button v-click-blur />
 */
export default {
	bind(el) {
		function clickHandler(e) {
			let target = e.target
			let i = 0
			// 最多向父级查找5层
			while (i < 5) {
				if (target.nodeName == 'BUTTON') {
					break
				}
				target = target.parentNode
				i++
			}
			target.blur()
		}
		// 给当前元素绑定个私有变量
		el.__clickBlur__ = clickHandler
		el.addEventListener('click', clickHandler)
	},
	unbind(el) {
		// 解除事件监听
		el.removeEventListener('click', el.__clickBlur__)
		delete el.__clickBlur__
	},
}
