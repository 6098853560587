import getReqUtil from '@/utils/reqUtils'
import { proxy } from '@/config'
const { reqGET, reqPOST, reqPUT } = getReqUtil(proxy)
import request from '@/utils/request'

// 获取租户已授予的设备类型列表
export const apiGetGrantedDeviceTypeList = data => reqGET(data, '/monitor/deviceType/granted-device/list')

// 获取租户未授予的设备类型列表
export const apiGetUnGrantedDeviceTypeList = data => reqPOST(data, `/monitor/deviceType/ungrant-device/list`)

// 设备类型撤销授予
export const apiCancelDeviceType = data => reqPOST(data, '/monitor/deviceType/cancel')

// 设备授予
export const apiGrantDeviceType = data => reqPOST(data, '/monitor/deviceType/grant')

// 获取租户列表
export const apiGetTenantList = params => reqGET(params, '/v1/utos/tenants')

// 激活管理员账号
export const apiActivateTenantAdmin = data => reqPOST(data, '/v1/utos/staffs/admin')

// 重置租户管理员密码
export const apiResetTenantAdminPassword = data => {
	// let paramsStr = "";
	// Object.keys(data).map((key, index) => {
	//   paramsStr += index == 0 ? `${key}=${data[key]}` : `&${key}=${data[key]}`;
	// });
	return request({
		url: `/auth/aggregation/password/sys/manage/update`,
		method: 'POST',
		params: data,
	})
}

// 创建租户
export const apiAddTenant = data => reqPOST(data, '/tum/tenant/create', false, false)

// 获取租户详情
export const apiGetTenantDetail = data => reqGET({}, `/v1/utos/tenants/${data}`)

// 修改租户信息
export const apiModifyTenant = data => reqPOST(data, '/tum/tenant/edit')

// 启用租户
export const apiEnableTenant = data => reqPOST(data, '/tum/tenant/enable')

// 禁用租户
export const apiDisableTenant = data => reqPOST(data, '/tum/tenant/unable')

// 获取租户配置
export const apiGetTenantConfigs = data => reqGET(data, '/v1/utos/tenants/configs')

// 设置租户参数
export const apiSetTenantConfigs = data => reqPUT(data, '/v1/utos/tenants/configs')

// 设置告警时长
export const apiSetSoundDuration = data => reqPUT(data, '/v1/utos/tenants/alarmSoundDuration')

//  获取租户权限模板
export const apiGetTenantPermissionTemplateList = data => reqGET(data, '/auth/authz/permission/template/scope/list')

// 获取租户权限模板内容
export const apiGetTenantPermissionTemplateTree = data =>
	reqPOST(data, '/auth/authz/permission/template/scope/tree', true)

// 获取租户使用的权限模板
export const apiGetTenantTemplate = data => reqGET(data, '/auth/authz/permission/template/scope/ref')

//  应用租户权限模板
export const apiSetTenantPermissionTemplate = data => reqPOST(data, '/auth/authz/permission/template/scope/ref/set')

// 获取组织结构模板列表
export const apiGetOrgTemplateList = data => reqGET(data, '/v1/utos/organizations/struct/templates')

// 获取组织结构模板内容
export const apiGetOrgTemplateContent = data => reqGET({}, `/v1/utos/organizations/struct/templates/${data}`)

// 保存组织机构
export const apiSetOrgTemplate = data => reqPOST(data, '/v1/utos/organizations/struct')

// 查询组织结构
export const apiGetOrgStruct = data => reqGET(data, '/v1/utos/organizations/struct')

// 标记组织机构数据隔离
export const apiSetIsolationBetweenOrg = data => reqPUT(data, '/v1/utos/organizations/struct/isolation')

// 获取数据隔离级别
export const apiGetIsolation = data => reqGET(data, '/v1/utos/organizations/struct/isolation')

// 获取操作记录
export const apiGetOperateList = data => reqGET(data, '/v1/utos/audit/log')
export const apiGetTenantFunctionTree = data => reqGET(data, '/auth/authz/functional/tree/system')

export const apiGetTenantGrantedFunction = data => reqGET(data, '/auth/authz/functional/granted/tenant')

export const apiSetTenantFunction = data => reqPOST(data, '/auth/authz/functional/granted/tenant')
