<template>
	<gc-dialog
		title="切换租户"
		width="650px"
		:show="true"
		:show-close="true"
		:show-cancel-btn="true"
		:close-on-press-escape="false"
		@ok="handleOk"
		@cancel="$emit('close-modal')"
		@close="$emit('close-modal')"
	>
		<div class="search-modal">
			<el-input
				@keyup.enter.native.stop="findTenantPageList"
				placeholder="输入租户名称关键字可快速检索"
				v-model="keywords"
				clearable
			>
				<i
					style="cursor: pointer"
					@click="findTenantPageList"
					slot="suffix"
					class="el-input__icon el-icon-search"
				></i>
			</el-input>
		</div>
		<div class="tenant-radio-list" v-loading="loading">
			<el-radio-group v-model="selectTenantId">
				<el-radio :label="item.id" v-for="item in tableData" :key="item.id">{{ item.name }}</el-radio>
			</el-radio-group>
		</div>
		<div class="pagination">
			<el-pagination
				background
				@current-change="handleCurrentChange"
				:current-page.sync="pagination.pageNo"
				:page-size="pagination.pageSize"
				layout="total, prev, pager, next"
				:total="pagination.total"
			></el-pagination>
		</div>
	</gc-dialog>
</template>

<script>
import { apiGetTenantList } from '@/apis/tenant.api'
import { apiSwitchTenant } from '@/apis/login.api'
export default {
	name: 'SwitchTenantDialog',
	data() {
		return {
			keywords: '',
			tableData: [],
			selectTenantId: this.$store.state.user.selectTenantId,
			pagination: {
				pageNo: 1,
				pageSize: 20,
				total: 0,
			},
			loading: false,
		}
	},

	created() {
		this.findTenantPageList()
	},

	methods: {
		async findTenantPageList() {
			const { pageNo, pageSize } = this.pagination
			const searchParams = {
				name: this.keywords,
				page: pageNo - 1,
				size: pageSize,
			}
			this.loading = true
			try {
				const data = await apiGetTenantList(searchParams)
				this.tableData = data.dataList || []
				this.pagination.total = data.total
			} catch (e) {
				this.tableData = []
			} finally {
				this.loading = false
			}
		},

		async handleSwitchTenant(tenantId) {
			await apiSwitchTenant({ tenantId })
		},

		async handleOk() {
			if (!this.selectTenantId) {
				return this.$message.warning('请选择一个租户')
			}
			await this.handleSwitchTenant(this.selectTenantId)
			location.reload()
		},

		handleCurrentChange(pageNo) {
			this.pagination.pageNo = pageNo
			this.findTenantPageList()
		},
	},

	computed: {
		selectTenantName() {
			return this.tableData.find(o => o.id === this.selectTenantId)?.name
		},
	},
}
</script>
<style lang="scss" scoped>
.tenant-radio-list {
	margin-top: 12px;
	height: 290px;
	::v-deep .el-radio-group {
		display: flex;
		flex-wrap: wrap;
		.el-radio {
			width: 50%;
			margin: 0;
			padding: 6px 0;
		}
	}
}
.pagination {
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #f1f1f190;
	padding-top: 6px;
	margin-top: 6px;
}
</style>
