// 取消重复请求相关方法

import axios from 'axios'
import qs from 'qs'

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()
/**
 * 添加请求
 * @param {Object} config
 */
export const addPending = config => {
	const key = [
		'REPEAT_REQUEST',
		config.method,
		config.url,
		qs.stringify(config.params),
		qs.stringify(config.data),
	].join('&')
	config.cancelToken =
		config.cancelToken ||
		new axios.CancelToken(cancel => {
			if (!pending.has(key) && config.pending === false) {
				// 如果 pending 中不存在当前请求且api中设置pending为false，则添加进去
				pending.set(key, cancel)
			}
		})
}
/**
 * 移除请求
 * @param {Object} config
 */
export const removePending = config => {
	const key = [
		'REPEAT_REQUEST',
		config.method,
		config.url,
		qs.stringify(config.params),
		qs.stringify(config.data),
	].join('&')
	if (pending.has(key)) {
		// 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
		const cancel = pending.get(key)
		cancel(key)
		pending.delete(key)
	}
}
/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
	for (const [key, cancel] of pending) {
		cancel(key)
	}
	pending.clear()
}
