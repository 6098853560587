<template>
	<div
		class="gc-tree-select el-cascader"
		v-clickoutside="() => (dropdownVisible = false)"
		@click="() => toggleDropDownVisible(true)"
	>
		<el-input
			ref="input"
			:class="{ 'is-focus': dropdownVisible, disabled: disabled }"
			:disabled="disabled"
			v-model="searchValue"
			@input="handleInput"
		>
			<template slot="suffix">
				<i
					v-if="clearBtnVisible"
					key="clear"
					class="el-input__icon el-icon-circle-close"
					@click.stop="handleClear"
				></i>
				<i
					v-else
					key="arrow-down"
					:class="['el-input__icon', 'el-icon-arrow-down', dropdownVisible && 'is-reverse']"
					@click.stop="toggleDropDownVisible()"
				></i>
			</template>
		</el-input>
		<div class="el-cascader__tags">
			<el-tag
				v-for="(tag, index) in tags"
				:key="tag.code"
				:closable="!disabled"
				:disable-transitions="true"
				type="info"
				@close="handleCloseTag(tag, index)"
			>
				{{ tag.name }}
			</el-tag>

			<input
				v-if="!disabled"
				class="el-cascader__search-input"
				v-model.trim="searchValue"
				placeholder="请选择"
				:disabled="disabled"
				@focus="toggleDropDownVisible(true)"
				@input="e => handleInput(searchValue, e)"
				@click.stop="toggleDropDownVisible(true)"
				@keydown.delete="handleDelete"
			/>
		</div>

		<el-popover
			placement="bottom-start"
			title=""
			:width="clientWidth"
			trigger="manual"
			popper-class="gc-tree-select-drop-down"
			v-model="dropdownVisible"
			@after-leave="handleDropdownLeave"
		>
			<div class="drop-down-content">
				<div class="drop-down-tree">
					<el-tree
						ref="selectTree"
						:node-key="treeProps.id"
						:data="options"
						default-expand-all
						show-checkbox
						check-strictly
						:expand-on-click-node="false"
						icon-class="el-icon-arrow-right"
						:props="treeProps"
						:filter-node-method="filterNode"
						:indent="12"
						:default-checked-keys="defaultCheckedKeys"
						@check="handleCheckChange"
					></el-tree>
				</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { isBlank } from '@/utils/validate'
import Clickoutside from 'element-ui/src/utils/clickoutside'

export default {
	name: 'GcTreeSelectMultiple',
	directives: { Clickoutside },

	model: {
		prop: 'value',
		event: 'update:value',
	},

	props: {
		value: {
			type: [String, Number, Array],
			default: null,
		},
		options: {
			type: Array,
			default: () => [],
		},
		treeProps: {
			type: Object,
			default: () => {
				return {
					id: 'id',
					label: 'label',
					children: 'children',
					disabled: 'disabled',
					isLeaf: 'isLeaf',
				}
			},
		},
		disabled: Boolean,
		placeholder: {
			type: String,
			default: '请选择',
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
		treeSelectClearable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		clearBtnVisible() {
			return this.tags.length > 0
		},
	},
	data() {
		return {
			cnName: '',
			dropdownVisible: false,
			searchValue: '',
			clientWidth: 0,

			closeFlag: false,
			defaultCheckedKeys: [],
			checkedNodes: [],
			tags: [],
			pressDeleteCount: 0,
		}
	},
	created() {},

	methods: {
		isBlank,
		handleCloseTag(item, index) {
			if (this.disabled) return

			this.tags.splice(index, 1)
			this.$refs.selectTree.setCheckedNodes(this.tags)
			this.updateModelValue()
		},
		updateStyle() {
			this.$nextTick(() => {
				const { $el } = this
				if (this.$isServer || !$el) return

				const inputInner = $el.querySelector('.el-input__inner')
				if (!inputInner) return
				const tags = $el.querySelector('.el-cascader__tags')
				if (tags) {
					// const offsetHeight = Math.round(tags.getBoundingClientRect().height);
					const offsetHeight = tags.offsetHeight
					const height = offsetHeight + 6
					inputInner.style.height = height + 'px'
				}
			})
		},
		handleDelete() {
			if (this.disabled) return
			const { searchValue, pressDeleteCount, tags } = this
			const lastIndex = tags.length - 1
			const lastTag = tags[lastIndex]
			this.pressDeleteCount = searchValue ? 0 : pressDeleteCount + 1

			if (!lastTag) return

			if (this.pressDeleteCount) {
				if (lastTag.hitState) {
					this.handleCloseTag(lastTag, lastIndex)
				} else {
					lastTag.hitState = true
				}
			}
		},
		getTags(checkedKeys, checkedNodes) {
			const res = JSON.parse(JSON.stringify(checkedKeys))
			const helper = node => {
				if (!node.children) return
				for (let i = 0; i < node.children.length; i++) {
					let n = node.children[i]
					let idx = res.indexOf(n[this.treeProps.id])
					if (idx > -1) {
						res.splice(idx, 1)
					}
					helper(n)
				}
			}
			for (let i = 0; i < checkedNodes.length; i++) {
				helper(checkedNodes[i])
			}
			return checkedNodes.filter(item => res.includes(item[this.treeProps.id]))
		},
		handleDropdownLeave() {
			this.searchValue = null
			this.$refs.selectTree.filter(null)
		},
		updateModelValue() {
			this.$emit(
				'update:value',
				this.tags.length ? this.tags.map(item => item[this.treeProps.id]).join(',') : null,
			)
		},
		handleClear() {
			if (this.disabled) return
			this.tags = []
			this.$refs.selectTree.setCheckedNodes([])
			this.updateModelValue()
		},
		handleCheckChange(currentNode, val) {
			const { checkedNodes } = val
			this.tags = checkedNodes
			this.updateModelValue()
		},
		toggleDropDownVisible(visible) {
			if (this.disabled) return
			const { dropdownVisible } = this
			const { input } = this.$refs
			visible = !this.isBlank(visible) ? visible : !dropdownVisible
			if (visible !== dropdownVisible) {
				this.dropdownVisible = visible
				if (visible) {
					input.$refs.input.setAttribute('aria-expanded', visible)
				}
			}
		},
		findTargetTreeItemById(id, list) {
			for (let i = 0; i < list.length; i++) {
				if (list[i][this.treeProps.id] == id) {
					return list[i]
				}
				if (list[i].children?.length) {
					const t = this.findTargetTreeItemById(id, list[i].children)
					if (t) return t
				}
			}
		},
		initTags() {
			this.defaultCheckedKeys = !isBlank(this.value) ? this.value.split(',') : []
			const checkedNodes = this.defaultCheckedKeys
				.map(item => this.findTargetTreeItemById(item, this.options))
				.filter(item => !!item)
			this.$nextTick(() => {
				this.$refs.selectTree?.setCheckedNodes(checkedNodes)
				this.tags = checkedNodes
			})
		},
		filterNode(value, data) {
			if (!value) return true
			return data[this.treeProps.label].indexOf(value) !== -1
		},
		handleInput(val) {
			if (this.disabled) return
			!this.dropdownVisible && this.toggleDropDownVisible(true)
			if (val) {
				this.$refs.selectTree.filter(this.searchValue)
			}
		},
	},

	watch: {
		tags: {
			handler() {
				this.updateStyle()
			},
			immediate: true,
			deep: true,
		},
		value: {
			handler() {
				this.initTags()
			},
			immediate: true,
			deep: true,
		},

		options: {
			handler() {
				this.$nextTick(() => {
					// this.setHighLightNode();
				})
			},
			immediate: true,
			deep: true,
		},

		dropdownVisible(val) {
			if (val) {
				this.$nextTick(() => {
					const parentNode = document.querySelector('.gc-tree-select')
					this.clientWidth = parentNode.clientWidth
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.el-cascader__search-input {
	background-color: transparent;
}
.el-cascader__tags {
	position: absolute;
	left: 0;
	right: 30px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	line-height: normal;
	text-align: left;
	box-sizing: border-box;
}
.el-cascader .el-input.disabled {
	cursor: none;
	pointer-events: none;
}
.gc-tree-select {
	width: 100%;
	height: auto;
	::v-deep .el-dropdown {
		width: 100%;
	}

	.tree-select-area {
		.select-show-label {
			position: relative;
			::v-deep .el-input {
				.el-input__inner {
					cursor: pointer;
				}
			}
			.icon-arrow {
				position: absolute;
				right: 12px;
				top: 50%;
				transition: 0.3s;
				transform: translateY(-50%);
				&.show-drop {
					transform: translateY(-50%) rotate(180deg);
				}
			}
			.icon-close {
				color: #c0c4cc;
			}
			&.disabled {
				.icon-arrow {
					opacity: 0.6;
				}
				::v-deep .el-input {
					.el-input__inner {
						cursor: not-allowed;
					}
				}
			}
		}
	}
}
</style>

<style lang="scss">
.gc-tree-select-drop-down {
	padding: 16px 12px !important;
	max-height: 230px;
	overflow-y: scroll;
	box-sizing: border-box;
	.drop-down-content {
		height: 100%;
		overflow: hidden;
		max-height: 194px;
		.drop-down-tree {
			max-height: 168px;
			overflow-y: scroll;
		}
		.btn-area {
			float: right;
			height: 26px;
			.mini {
				padding: 9px 15px;
				font-size: 12px !important;
				border-radius: 3px;
				line-height: 6px;
				height: auto !important;
			}
		}
	}

	.el-tree {
		margin-top: 0 !important;
		.el-tree-node {
			&__content {
				height: 36px;
				border-radius: 4px;
				.el-icon-arrow-right:not(.is-leaf) {
					color: #3f435e;
					font-size: 10px;
					font-weight: bold;
				}
				.custom-tree-node {
					flex: 1;
					width: 0;
					align-items: center;
					color: #3f435e;
					font-size: 14px;
					.el-dropdown-menu__item {
						width: 100%;
						line-height: 36px;
						padding: 0;
						&:hover {
							background-color: transparent;
							color: #4d6bff;
						}
					}
				}
			}
			&.is-current {
				& > .el-tree-node__content {
					.custom-tree-node {
						.el-dropdown-menu__item {
							color: #4d6bff;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
</style>
